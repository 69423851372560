import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MoreVertIcon from '@mui/icons-material/SearchSharp';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import 'dayjs/locale/es';
import { useEffect, useState } from 'react';

import ICustomer from '../../../../core/interfaces/ICustomer';
import { getCustomers, getCustomersFilter } from '../../../../source/apiCustomers';
import '../../../../source/css/dialog-title.css';
import CustomerList from './CustomerList';

export default function CustomerSearchModal(props: any) {
    const [errorMsg, setErrorMsg] = useState('');
    const [customer, setCustomer] = useState([] as ICustomer[]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const loadCustomers = async () => {
        const data = await getCustomers(page);
        setCustomer(data);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleSearch = (textToSearch: string) => {
        setIsLoading(true);
        setPage(1);
        setTimeout(async () => {
            const data = await getCustomersFilter(page, textToSearch);
            setCustomer(data);
            setIsLoading(false);
        }, 1000);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleSelectCustomerUpdate = (customer: ICustomer) => {
        props.handleSelectCustomer(customer);
        props.handleClose();
    }

    useEffect(() => {
        setIsLoading(true);
        loadCustomers();
    }, [page]);


    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle className="custom-dialog-search-title" sx={{ marginBottom: '10px' }}>
                <MoreVertIcon /> Buscar Clientes
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        autoFocus
                        id="standard-basic"
                        placeholder='Buscar clientes...'
                        sx={{ width: '90%', marginBottom: 1 }}
                        onChange={(e) => handleSearch((e.target as HTMLInputElement).value.toString())}
                    />
                    <Button onClick={(e) => handleSearch((e.target as HTMLInputElement).value.toString())} sx={{ width: '10%', marginTop: '10px' }}>
                        <SearchRoundedIcon />
                    </Button>
                </DialogContentText>
                <Box sx={{ textAlign: 'center' }}>
                    {isLoading &&
                        <>
                            <br /><br />
                            <CircularProgress />
                        </>
                    }
                    {!isLoading &&
                        <CustomerList
                            page={page}
                            rows={customer}
                            handleChangePage={handleChangePage}
                            handleSelectCustomerUpdate={handleSelectCustomerUpdate}
                        />
                    }
                </Box>

                {errorMsg !== '' &&
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMsg}
                    </Alert>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}