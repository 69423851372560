import axios from 'axios';

import { getCompanyID, isLoggedIn } from './accessControl';
import ApiHeaderConfig, { API_BASE_URL } from './header.config';

export const GetAllSuppliers = async (page: number): Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/suppliers?pageSize=10&page=${page}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`GetRecipeByID: ${err.message}`);
    }
};

export const getSuppliers = async (page: number)  => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }
    
    try {
        const response = await axios.get(`${API_BASE_URL}/suppliers?pageSize=10&page=${page}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los proveedores:', error);
        throw error;
    }
};

export const getSupplierById = async (supplierId: number) : Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/suppliers/${supplierId}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener el proveedor:', error);
        throw error;
    }
};
// Crear un nuevo producto
export const createSuppliers = async (supplierData: any) : Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        supplierData.company_id = String(getCompanyID());
        const response = await axios.post(`${API_BASE_URL}/suppliers`, supplierData, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al crear el proveedor:', error);
        throw error;
    }
};

// Actualizar un producto existente
export const updateSuppliers = async (productId: number, supplierData: any) : Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.put(`${API_BASE_URL}/suppliers/${productId}`, supplierData, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el proveedor:', error);
        throw error;
    }
};

