import PrintIcon from '@mui/icons-material/Print';
import * as React from "react";
import ReactToPrint from "react-to-print";
import Button from '@mui/material/Button';


import { VoucherPdfFormat } from "./Voucher";
import { ReceipePdfFormat } from "./Receipe";

export const PrintCtrl = (props) => {
    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const handleAfterPrint = React.useCallback(() => {

    }, []);

    const handleBeforePrint = React.useCallback(() => {

    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {

        const contentToPrint = componentRef.current.cloneNode(true);

        const elementsToRemove = contentToPrint.querySelectorAll('.no-imprimir');

        elementsToRemove.forEach(element => element.remove());

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                resolve();
            }, 2000);
        });
    }, []);

    React.useEffect(() => {
        if (
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {

        return <button>Print using a Functional Component</button>;
    }, []);


    return (
        <div>
            <ReactToPrint
                numberOfCopies={1}
                content={reactToPrintContent}
                documentTitle="AwesomeFileName"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint
                trigger={() =>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                    >
                        &nbsp;Imprimir
                    </Button>}
            />
            <div style={{ display: "none" }}>
                {props.type === 'receipe' ?
                    <ReceipePdfFormat
                        forwardedRef={componentRef}
                        Customer={props.Customer}
                        Receipe={props.Receipe}
                    />
                    :
                    <VoucherPdfFormat
                        forwardedRef={componentRef}
                        text={props.text}
                        Customer={props.Customer}
                        Receipe={props.Receipe}
                        Voucher={props.Voucher}
                    />
                }
            </div>
        </div>
    );
};

export default PrintCtrl;