import IProduct from "../interfaces/IProducts";
import IOperativeVoucher from "../interfaces/IVoucher";

export interface IOperativeVoucherDTO {
    operative_recipe_id?: number;
    voucher_date?: Date;
    total?: number;
    credit?: number;
    balance?: number;
    is_cash?: boolean;
    cash?: number;
    is_debit?: boolean;
    debit?: number;
    is_credit_card?: boolean;
    credit_card?: number;
    is_transfert?: boolean;
    transfert?: number;
    discount_percent?: number;
    discount_value?: number;
    total_without_discount?: number;
    voucher_end_date?: Date;
    user_id: number;
    company_id?: string;
    active?: boolean;
    update_at?: Date;
    operative_voucher_detail: IOperativeVoucherDetaileDTO[];
}

export interface IOperativeVoucherUpdateDTO {
    id: number;
    operative_recipe_id?: number;
    voucher_date?: Date;
    total?: number;
    credit?: number;
    balance?: number;
    is_cash?: boolean;
    cash?: number;
    is_debit?: boolean;
    debit?: number;
    is_credit_card?: boolean;
    credit_card?: number;
    is_transfert?: boolean;
    transfert?: number;
    discount_percent?: number;
    discount_value?: number;
    total_without_discount?: number;
    voucher_end_date?: Date;
    user_id: number;
    company_id?: string;
    active?: boolean;
    update_at?: Date;
    operative_voucher_detail: IOperativeVoucherDetaileDTO[];
}

export interface IOperativeVoucherDetaileDTO {
    id?: number,
    operative_voucher_id: number;
    product_id: number;
    quantity: number;
    value_sale: number;
    order: number;
    update_at: Date;
    product?: IProduct;
}

export const CastVoucherToDTO = (voucher: IOperativeVoucher): IOperativeVoucherDTO => {
    return {
        operative_recipe_id: voucher.operative_recipe_id,
        voucher_date: voucher.voucher_date ?? new Date(),
        total: voucher.total ?? 0,
        credit: voucher.credit ?? 0,
        balance: voucher.balance ?? 0,
        active: voucher.active ?? true,
        is_cash: voucher.is_cash ?? false,
        cash: voucher.cash ?? 0,
        is_debit: voucher.is_debit ?? false,
        debit: voucher.debit ?? 0,
        is_credit_card: voucher.is_credit_card ?? false,
        credit_card: voucher.credit_card ?? 0,
        is_transfert: voucher.is_transfert ?? false,
        transfert: voucher.transfert ?? 0,
        discount_percent: voucher.discount_percent ?? 0,
        discount_value: voucher.discount_value ?? 0,
        total_without_discount: voucher.total_without_discount ?? 0,
        voucher_end_date: voucher.voucher_end_date ?? new Date(),
        user_id: voucher.user_id ?? 0,
        company_id: voucher.company_id ?? "",
        operative_voucher_detail: CastProductToDTO(voucher.operative_recipe_id, voucher.products as IProduct[]),
    };
};

export const CastVoucherToUpdateDTO = (voucher: IOperativeVoucher): IOperativeVoucherUpdateDTO => {
    return {
        id: voucher.id,
        operative_recipe_id: voucher.operative_recipe_id,
        voucher_date: voucher.voucher_date ?? new Date(),
        total: voucher.total ?? 0,
        credit: voucher.credit ?? 0,
        balance: voucher.balance ?? 0,
        active: voucher.active ?? true,
        is_cash: voucher.is_cash ?? false,
        cash: voucher.cash ?? 0,
        is_debit: voucher.is_debit ?? false,
        debit: voucher.debit ?? 0,
        is_credit_card: voucher.is_credit_card ?? false,
        credit_card: voucher.credit_card ?? 0,
        is_transfert: voucher.is_transfert ?? false,
        transfert: voucher.transfert ?? 0,
        discount_percent: voucher.discount_percent ?? 0,
        discount_value: voucher.discount_value ?? 0,
        total_without_discount: voucher.total_without_discount ?? 0,
        voucher_end_date: voucher.voucher_end_date ?? new Date(),
        user_id: voucher.user_id ?? 0,
        company_id: voucher.company_id ?? "",
        operative_voucher_detail: CastProductToDTO(voucher.operative_recipe_id, voucher.products as IProduct[]),
    };
};

export const CastDTOToProduct = (products: IOperativeVoucherDetaileDTO[]): IProduct[] => {
    return products.map((prd) => {
        return {
            detailId: prd.id,
            id: prd.product_id,
            sku: prd.product?.sku,
            quantity: prd.quantity,
            value_sale: prd.value_sale,
            total: prd.quantity * prd.value_sale,
            name: prd.product?.name,
            brand: prd.product?.brand,
        };
    }) as IProduct[];
};

export const CastProductToDTO = (id: number, product: IProduct[]): IOperativeVoucherDetaileDTO[] => {
    const info = product.map((prd, index) => {
        return {
            id: prd.detailId,
            operative_voucher_id: id,
            product_id: prd.id,
            quantity: prd.quantity,
            value_sale: prd.value_sale,
            order: index,
            update_at: new Date()
        };
    }) as IOperativeVoucherDetaileDTO[];

    return info;
}