import BackspaceIcon from '@mui/icons-material/LibraryAddCheck';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { formatNumberWithRegex } from '../../../../../src/source/utils/extends';
import { Link } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#147ba3",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ProductsListCtrl = (props: any) => {

    useEffect(() => {
    }, [props.rows.length])

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 200 }} aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>SKU</StyledTableCell>
                        <StyledTableCell>Nombre</StyledTableCell>
                        <StyledTableCell align="right">Valor</StyledTableCell>
                        <StyledTableCell align="right">Sel</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows?.products.map((row: any) => (
                        <StyledTableRow hover key={row.id}>
                            <TableCell>{row.sku}</TableCell>
                            <TableCell>{row.name} - ({row.brand})</TableCell>
                            <TableCell align="right">{formatNumberWithRegex(row.value_sale)}</TableCell>
                            <TableCell align="right">
                                <Link href="#" onClick={() => props.handleProductSaleUpdated(row)}>
                                    <BackspaceIcon />
                                </Link>
                            </TableCell>
                        </StyledTableRow >
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            colSpan={1}
                            count={props?.rows.total}
                            rowsPerPage={10}
                            page={props.page}
                            onPageChange={props.handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default ProductsListCtrl;