import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import 'dayjs/locale/es';
import React, { useEffect, useState } from 'react';

import { VoucherList } from '../../../source/apiVoucher';
import VoucherListCtrl from '../../components/Voucher/List';
import PrintCtrl from '../../components/Print';
import IOperativeVoucher from '../../../core/interfaces/IVoucher';

//TODO eliminar
import mockCustomer1 from '../../../mocks/Customer';
import mockOperativeVoucher from '../../../mocks/OperativeVoucher';
import mockRecipe from '../../../mocks/Receipe';

export default function VentasClosed(props: any) {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1)

    //TODO eliminar
    const customerInfo = {
        name: 'Jhon Doe',
        address: 'Calle 123',
        phone: '1234567890',
        email: ''
    }

    const loadData = async (page: number) => {
        try {
            setIsLoading(true);
            setPage(page);
            const response = await VoucherList(page, true);
            setData(response);
            setIsLoading(false);
        } catch (error) {
            console.log((error as Error).message)
        }
    }

    useEffect(() => {
        loadData(1);
    }, [])


    return (
        <React.Fragment>
            <CssBaseline />
            <Container component="main" >
                <Paper variant="outlined" style={{ margin: '10px' }}>
                    <VoucherListCtrl
                        Title="Ventas Cerradas"
                        contractData={data}
                        handleBackTo={() => { }}
                        handleChangePage={loadData}
                    />
                    {/* <PrintCtrl
                        type='voucher'
                        text={'Operative Name 2'}
                        Customer={mockCustomer1[0]}
                        Receipe={mockRecipe}
                        Voucher={mockOperativeVoucher}
                        handlerUpdateVoucher={(v: IOperativeVoucher ) => { }}
                    /> */}

                </Paper>
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}