import React from 'react';
import Grid from '@mui/material/Grid';

const DiagonalLineGridItem = () => {
    return (
        <Grid
            item
            xs={2}
            sm={2}
            sx={{
                marginLeft: '20px',
                border: 1,
                borderColor: 'black',
                position: 'relative',
                overflow: 'hidden',
                height: '25px'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: '150%',
                    height: '1px', 
                    background: 'black',
                    transform: 'rotate(-14deg)',
                    transformOrigin: 'top left',
                }}
            />
            &nbsp;
        </Grid>
    );
}

export default DiagonalLineGridItem;
