import IProduct from "./IProducts";

export default interface IOperativeVoucher {
    id: number;
    operative_recipe_id: number;
    voucher_date?: Date;
    total?: number;
    credit?: number;
    balance?: number;
    active?: boolean;
    created_at?: Date;
    update_ad?: Date;
    operative_id?: number;
    filter_id?: number;
    laboratory_id?: number;
    photocromatic?: string;
    is_dyeing?: boolean;
    is_delivered?: boolean;
    is_active?: boolean;
    is_cash?: boolean;
    cash?: number;
    is_debit?: boolean;
    debit?: number;
    is_credit_card?: boolean;
    credit_card?: number;
    is_transfert?: boolean;
    transfert?: number;
    discount_percent?: number;
    discount_value?: number;
    total_without_discount?: number;
    voucher_end_date?: Date;
    user_id?: number;
    company_id?: string;
    products?: IProduct[];
}


export const emptyOperativeVoucher: IOperativeVoucher = {
    id: 0,
    operative_recipe_id: 0,
    voucher_date: undefined,
    total: 0,
    credit: 0,
    balance: 0,
    created_at: undefined,
    update_ad: undefined,
    operative_id: 0,
    filter_id: 0,
    laboratory_id: undefined,
    photocromatic: undefined,
    is_dyeing: false,
    is_delivered: false,
    is_active: false,
    cash: 0,
    debit: 0,
    credit_card: 0,
    transfert: 0,
    discount_percent: 0,
    discount_value: 0,
    total_without_discount: 0,
    products: [] as IProduct[]
};
