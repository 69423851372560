import AddToQueue from '@mui/icons-material/AddToQueue';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import PeopleIcon from '@mui/icons-material/People';
import MoreVertIcon from '@mui/icons-material/ProductionQuantityLimits';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Divider } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';

export const mainListItems = (props: any) => (
    <React.Fragment>
        <ListItemButton onClick={() => props.changeOption(0)} >
            <ListItemIcon>
                <AddToQueue />
            </ListItemIcon>
            <ListItemText primary="Operativos" />
        </ListItemButton>
        <ListItemButton onClick={() => props.changeOption(1)} >
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Ingresar Examen" />
        </ListItemButton>
        <ListItemButton onClick={() => props.changeOption(2)} >
            <ListItemIcon>
                <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Emitir Nota Venta" />
        </ListItemButton>
        <ListItemButton onClick={() => props.changeOption(3)} >
            <ListItemIcon>
                <ShoppingBagOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Ventas Cerradas" />
        </ListItemButton>
        <ListItemButton onClick={() => props.changeOption(4)} >
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Reportes" />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => props.changeOption(5)} >
            <ListItemIcon>
                <MoreVertIcon />
            </ListItemIcon>
            <ListItemText primary="Productos" />
        </ListItemButton>
        <ListItemButton onClick={() => props.changeOption(6)} >
            <ListItemIcon>
                <FaceRetouchingNaturalIcon />
            </ListItemIcon>
            <ListItemText primary="Clientes" />
        </ListItemButton>
        <ListItemButton onClick={() => props.changeOption(7)} >
            <ListItemIcon>
                <ContactMailIcon />
            </ListItemIcon>
            <ListItemText primary="Proveedores" />
        </ListItemButton>
        <ListItemButton onClick={() => props.changeOption(8)} >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Administración" />
        </ListItemButton>
    </React.Fragment>
);
