import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DangerousIcon from '@mui/icons-material/Dangerous';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import Link from '@mui/material/Link';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { formatDate } from '../../../../source/utils/extends';
import NotFound from '../../../components/NotFound';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ReceipesOpenedListCtrl = (props: any) => {
    const [page, setPage] = useState(0);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        props.handleChangePage(newPage + 1);
    };

    return (
        props?.contractData?.receipes?.length > 0 ? (
            <>
                <Typography component="h1" variant="h4" align="center" sx={{ margin: '20px' }}>
                    {props.Title}
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Atención</StyledTableCell>
                                <StyledTableCell>Fecha Operativo</StyledTableCell>
                                <StyledTableCell>Cliente</StyledTableCell>
                                <StyledTableCell>Operativo</StyledTableCell>
                                <StyledTableCell>¿Con Nota de Venta?</StyledTableCell>
                                {props.isForEdit &&
                                    <StyledTableCell align='center'>Editar</StyledTableCell>
                                }
                                {props.isForSale &&
                                    <StyledTableCell align='center'>Vender</StyledTableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props?.contractData?.receipes?.map((contract: any) => (
                                <StyledTableRow key={contract.id}>
                                    <StyledTableCell>{contract.correlative_id}</StyledTableCell>
                                    <StyledTableCell>{formatDate(contract.date_at)}</StyledTableCell>
                                    <StyledTableCell>{contract.customer.first_name} {contract.customer.last_name}</StyledTableCell>
                                    <StyledTableCell>{contract.operative?.name}</StyledTableCell>
                                    <StyledTableCell align='center'>{contract.operative_voucher?.length === 0 ? <DangerousIcon /> : <CheckCircleOutlineIcon />}</StyledTableCell>
                                    {props.isForPrint &&
                                        <StyledTableCell align='center'>
                                            <Link href="#" onClick={() => props.handlePrintReceipe(contract.id)}> <PictureAsPdfIcon /> </Link>
                                        </StyledTableCell>
                                    }
                                    {props.isForEdit &&
                                        <StyledTableCell align='center'>
                                            <Link href="#" onClick={() => props.handleEditContract(contract.id)}> <EditNoteIcon /> </Link>
                                        </StyledTableCell>
                                    }
                                    {props.isForSale &&
                                        <StyledTableCell align='center'>
                                            <Link href="#" onClick={() => props.handleSaleReceipe(contract.id)}> <ShoppingBasketIcon /> </Link>
                                        </StyledTableCell>
                                    }
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    colSpan={2}
                                    count={props?.contractData?.total}
                                    rowsPerPage={10}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </>
        ) :
            <NotFound />
    )
};

ReceipesOpenedListCtrl.defaultProps = {
    isForSale: false,
    isForEdit: false,
    isForPrint: false
};

export default ReceipesOpenedListCtrl;
