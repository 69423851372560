import React, { useEffect, useState } from 'react';
import NumericTable from './NumericTable';

import { CristalColorsList } from '../../../source/apiCristals';

const ParentComponent: React.FC = () => {
    const [data, setData] = useState<(number | null)[][]>(Array.from({ length: 10 }, () => Array.from({ length: 5 }, () => null)));

    const [cristalInfo, setCristalInfo] = useState({cristal: []});
    const [cristalCouynt, setCristalCount] = useState(0);

    const handleCellChange = (rowIndex: number, colIndex: number, newValue: number | null) => {
        setData((prevData) => {
            const newRow = [...prevData[rowIndex]];
            newRow[colIndex] = newValue;
            return [...prevData.slice(0, rowIndex), newRow, ...prevData.slice(rowIndex + 1)];
        });
    };

    const loadCristalInfo = async () => {
        const data = await CristalColorsList();
        setCristalInfo(data);
        setCristalCount(data.total);
    };

    useEffect(() => {
        loadCristalInfo();
    }, [cristalCouynt]);


    return <div>
        </div>;
};

export default ParentComponent;