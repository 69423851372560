import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import Customer from '../../../core/interfaces/ICustomer';
import Receipe from '../../../core/interfaces/Receipe';
import { CustomerCreate, GetRecipeByID, OperativeClose, OperativeOpenedList, ReceipeByOperative, ReceipeCreate } from '../../../source/api';
import { CristalColorsList } from '../../../source/apiCristals';
import { OperativosOpenedList } from '../../components/Operatives/List/OpenedList';
import ReceipeEditCtrl from '../../components/Receipes/FormEdit';
import ReceipesOpenedListCtrl from '../../components/Receipes/ListOpened';


export default function Tecnologa() {
    const [data, setData] = useState({ total: 0, operatives: [] });
    const [openModalOperative, setOpenModalOperative] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSavedOK, setIsSavedOK] = useState(false);
    const [operativeId, setOperativeId] = useState(0);
    const [receipes, setReceipes] = useState({ total: 0, receipes: [] });
    const [cristalInfo, setCristalInfo] = useState([]);
    const [fullInfo, setFullInfo] = useState(null);
    const [isJustForEdit, setIsJustForEdit] = useState(false);
    const [enabledPrint, setEnabledPrint] = useState(false);
    const [ctrlSwitch, setCtrlSwitch] = useState(0);

    const handleSelectOperative = (id: number) => {
        setOperativeId(id);
        setCtrlSwitch(1)
    };

    const handleSelectReceipe = async (id: number) => {
        try {
            setIsLoading(true);
            const receipeData = await GetRecipeByID(id);
            setEnabledPrint(true);
            setFullInfo(receipeData);
            setCtrlSwitch(2);
        } catch (error) {
            console.log((error as Error).message)
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    const handlelistReceipes = async (id: number) => {
        try {
            setIsLoading(true);
            const receipesData = await ReceipeByOperative(id, 1);
            setReceipes(receipesData);
            setOperativeId(id)
            setCtrlSwitch(3);
        } catch (error) {
            console.log((error as Error).message)
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const handleModalOperative = (id: number) => {
        setOpenModalOperative(true)
        setOperativeId(id)
    };

    const handleCloseOperative = async () => {
        try {
            setIsLoading(true);
            await OperativeClose(operativeId);
            setOpenModalOperative(false);
            setOperativeId(0);
        } catch (error) {
            console.log((error as Error).message)
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    const loadData = async (page: number) => {
        try {

            const response = await OperativeOpenedList(page);

            setData(response);

            const response2 = await CristalColorsList();

            setCristalInfo(response2);

        } catch (error) {
            console.log((error as Error).message)
        }
    }

    useEffect(() => {
        loadData(0);
    }, [])

    const handleSaveData = async (customer: Customer, receipe: Receipe) => {
        setIsLoading(true);

        let customerId = customer.id;
        let receipe_to_save: Receipe = receipe;

        receipe_to_save.operative_id = operativeId;

        if (customerId === 0) {
            customer.rut = customer.rut.replace(/[.-]/g, '');
            let customerData = await CustomerCreate(customer);
            customerId = customerData.id;
        }

        receipe_to_save.customer_id = Number(customerId);

        await ReceipeCreate(receipe_to_save);

        setIsSavedOK(true);

        setTimeout(() => {
            setIsLoading(false);
            setIsSavedOK(false);
            setEnabledPrint(true);
        }, 500);

    };

    const handleClearReceipe = () => {
        setIsJustForEdit(false);
        setFullInfo(null);
        setCtrlSwitch(0);
    };

    const handleGetControl = (): any => {
        if (ctrlSwitch === 0)
            return (<>
                <Typography component="h1" variant="h4" align="center" sx={{ margin: '20px' }}>
                    Operativos Abiertos
                </Typography>
                {!isLoading &&
                    <OperativosOpenedList
                        contractData={data}
                        handleSelectOperative={(id: number) => { handleSelectOperative(id) }}
                        handleCloseContract={(id: number) => { handleModalOperative(id) }}
                        handlelistReceipes={(id: number) => { handlelistReceipes(id) }}
                        handleChangePage={(page: number) => { loadData(page) }}
                    />
                }
                <Alert severity="info">
                    En la opción Cerrar. Puede finalizar un operativo.
                </Alert>
            </>);
        else if (ctrlSwitch === 3)
            return <>
                {!isLoading &&
                    <ReceipesOpenedListCtrl
                        Title="Recetas Ingresadas"
                        isForEdit
                        contractData={receipes}
                        handleSelectOperative={(id: number) => { }}
                        handleEditContract={(id: number) => {
                            setIsJustForEdit(true);
                            handleSelectReceipe(id)
                        }}
                        handleSaleReceipe={(id: number) => { }}
                        handleChangePage={(page: number) => { loadData(page) }}
                        NotFoundInfo="No existen atenciones realizadas"
                    />
                }
                <Typography align="center" style={{ margin: '15px' }}>
                    <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => {
                        handleClearReceipe();
                    }}>Volver a Operativos</Button>
                </Typography>
            </>
        else if (ctrlSwitch === 1 || ctrlSwitch === 2)
            return <ReceipeEditCtrl
                Receipe={fullInfo || {}}
                justForEdit={isJustForEdit}
                enabledPrint={enabledPrint}
                OperativeCounts={data?.total}
                OperativeId={operativeId}
                handlBackToOperatives={() => { 
                    setFullInfo(null);
                    setCtrlSwitch(0) 
                }}
                Fotocromatico={cristalInfo}
                handleModifiedAlert={(value: boolean) => { setIsJustForEdit(false) }}
                handleOnSave={(customer: Customer, newReceipe: Receipe) => { handleSaveData(customer, newReceipe) }}
            />

    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Container component="main" >
                <Paper variant="outlined" style={{ margin: '10px' }}>

                    {handleGetControl()}

                    {isSavedOK &&
                        <Alert variant="filled" severity="success">
                            <AlertTitle>Correcto</AlertTitle>
                            Atención Guardada correctamente
                        </Alert>
                    }
                </Paper>
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* cerrar el operativo */}
            <Dialog
                open={openModalOperative}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Cerrar Operativo"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        ¿Esta seguro de cerrar el operativo {operativeId}?

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenModalOperative(false) }}>Cancelar</Button>
                    <Button onClick={() => { handleCloseOperative() }} autoFocus>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}