import MoreVertIcon from '@mui/icons-material/ProductionQuantityLimits';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import 'dayjs/locale/es';
import { useState } from 'react';

import ISupplier from '../../../../core/interfaces/ISuppliers';
import '../../../../source/css/dialog-title.css';

export default function CreateSupplierCtrl(props: any) {
    const [errorMsg, setErrorMsg] = useState('');

    const [infoSupplier, setInfoSupplier] = useState<ISupplier>(props.infoSupplier?.id > 0 ? props.infoSupplier : { active: true });
    const isEditing = props.infoSupplier?.id > 0;

    const handleChange = (event: any) => {
        const { name, value, type } = event.target;

        let newValue = value;

        if (type === 'number') {
            newValue = parseInt(value);
        };

        setInfoSupplier(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const validateFields = (): boolean => {
        if (infoSupplier.name === undefined || infoSupplier.name === '') {
            setErrorMsg('El nombre del proveedor es obligatorio');
            return false;
        }

        props.handleSave(infoSupplier, isEditing);
        return true;
    }


    const handleChangeCheckbox = (event: any) => {
        const { name } = event.target;

        let newValue = !eval(`infoSupplier.${name}`);

        setInfoSupplier(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle className="custom-dialog-title" sx={{ marginBottom: '10px' }}>
                <MoreVertIcon /> Agregar/Editar Proveedores
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Información del Proveedor:
                </DialogContentText>
                <Box>
                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="idName"
                            label="Nombre del Proveedor"
                            name="name"
                            fullWidth
                            required
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 100
                            }}
                            value={infoSupplier?.name}
                        />
                    </Stack>

                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="contactname"
                            name="contactname"
                            label="Contacto"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 40
                            }}
                            value={infoSupplier?.contactname}
                        />
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            label="e-Mail"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 100
                            }}
                            value={infoSupplier?.email}
                        />

                        <FormControl sx={{ m: 0 }} variant="standard" fullWidth>
                            <InputLabel htmlFor="standard-adornment-amount">Teléfono</InputLabel>
                            <Input
                                id="phone"
                                name="phone"
                                startAdornment={<InputAdornment position="start">+56</InputAdornment>}
                                value={infoSupplier?.phone}
                                inputProps={{
                                    maxLength: 50
                                }}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="rut"
                            name="rut"
                            label="RUT"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 100
                            }}
                            value={infoSupplier?.rut}
                        />

                        <FormControl sx={{ m: 0 }} fullWidth variant="standard" >
                            <FormControlLabel
                                control={<Checkbox
                                    id='active'
                                    name='active'
                                    onChange={handleChangeCheckbox}
                                    checked={infoSupplier?.active}
                                />}
                                label="¿ Proveedor Activo ?"
                                labelPlacement="end"
                                style={{ marginRight: '40px' }}
                            />
                        </FormControl>
                    </Stack>

                </Box>

                {errorMsg !== '' &&
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMsg}
                    </Alert>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={validateFields}>Grabar</Button>
            </DialogActions>
        </Dialog>
    );
}