import IProducts from '../interfaces/IProducts';

export interface IProductsCreateDTO {
    company_id: string;
    supplier_id: number;
    material_id: number;
    sku?: string;
    brand?: string;
    name?: string;
    shape?: string;
    value_cost?: number | null;
    value_sale?: number | null;
    quantity?: number;
    total?: number;
    active?: boolean;
}


export const GetProductsCreateDTO = (product: IProducts) => {
    return ({
        company_id: product.company_id,
        sku: product.sku,
        brand: product.brand,
        name: product.name,
        shape: product.shape,
        value_cost: product.value_cost,
        value_sale: product.value_sale,
        quantity: product.quantity,
        total: product.total,
        supplier_id: product.supplier_id,
        material_id: product.material_id,
    });
};