import EditNoteIcon from '@mui/icons-material/EditNote';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import Link from '@mui/material/Link';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { formatDate, formatNumberWithRegex, formatRut } from '../../../../source/utils/extends';
import NotFound from '../../../components/NotFound';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const VoucherListCtrl = (props: any) => {
    const [page, setPage] = useState(0);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        props.handleChangePage(newPage + 1);
    };

    return (
        props?.contractData?.vouchers?.length > 0 ? (
            <>
                <Typography component="h1" variant="h4" align="center" sx={{ margin: '20px' }}>
                    {props.Title}
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>ID</StyledTableCell>
                                <StyledTableCell>Fecha Voucher</StyledTableCell>
                                <StyledTableCell>Fecha Entrega</StyledTableCell>
                                <StyledTableCell>Cliente</StyledTableCell>
                                <StyledTableCell>RUT</StyledTableCell>
                                <StyledTableCell>Celular</StyledTableCell>
                                <StyledTableCell>Total</StyledTableCell>
                                {props.isForPrint &&
                                    <StyledTableCell align='center'>Print</StyledTableCell>
                                }
                                {props.isForEdit &&
                                    <StyledTableCell align='center'>Editar</StyledTableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props?.contractData?.vouchers?.map((contract: any) => (
                                <StyledTableRow key={contract.id}>
                                    <StyledTableCell>{contract.correlative_id}</StyledTableCell>
                                    <StyledTableCell>{formatDate(contract.voucher_date)}</StyledTableCell>
                                    <StyledTableCell>{formatDate(contract.voucher_end_date)}</StyledTableCell>
                                    <StyledTableCell>{contract.operative_recipe.customer.first_name} {contract.operative_recipe.customer.last_name}</StyledTableCell>
                                    <StyledTableCell>{formatRut(contract.operative_recipe.customer.rut)}</StyledTableCell>
                                    <StyledTableCell>{contract.operative_recipe.customer.cell_phone}</StyledTableCell>
                                    <StyledTableCell>{formatNumberWithRegex(contract.total)}</StyledTableCell>
                                    {props.isForPrint &&
                                        <StyledTableCell align='center'>
                                            <Link href="#" onClick={() => props.handlePrintReceipe(contract.id)}> <PictureAsPdfIcon /> </Link>
                                        </StyledTableCell>
                                    }
                                    {props.isForEdit &&
                                        <StyledTableCell align='center'>
                                            <Link href="#" onClick={() => props.handleEditContract(contract.id)}> <EditNoteIcon /> </Link>
                                        </StyledTableCell>
                                    }
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    colSpan={2}
                                    count={props?.contractData?.total}
                                    rowsPerPage={10}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </>
        ) :
            <NotFound />
    )
};

VoucherListCtrl.defaultProps = {
    isForEdit: false,
    isForPrint: false
};

export default VoucherListCtrl;
