// src/api.ts
import axios, { AxiosResponse } from 'axios';
import { getCompanyID, isLoggedIn } from './accessControl';
import ApiHeaderConfig, { API_BASE_URL } from './header.config';

interface LoginResponse {
    token: string;
    message: string;
    company_id: string;
}

export const login = async (username: string, password: string): Promise<LoginResponse> => {
    try {
        const response: AxiosResponse<LoginResponse> = await axios.post(`${API_BASE_URL}/auth/login`, { username, password });
        return response.data;
    } catch (error) {
        throw new Error('Error during login');
    }
};

export const OperativeList = async (page: number): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }
    console.log(`${API_BASE_URL}/operatives?pageSize=10&page=${page}`)
        const response: AxiosResponse<LoginResponse> = await axios.get(`${API_BASE_URL}/operatives?pageSize=10&page=${page}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`OperativeList: ${err.message}`);
    }
};

export const OperativeCreate = async (operative: any): Promise<any> => {

    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }
        operative.company_id = getCompanyID();
        const response: AxiosResponse<LoginResponse> = await axios.post(`${API_BASE_URL}/operatives`, operative, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`OperativeCreate: ${err.message}`);
    }
}

export const OperativeClose = async (operativeId: number): Promise<any> => {

    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        const operative = {
            is_closed: true
        };

        const response: AxiosResponse<LoginResponse> = await axios.put(`${API_BASE_URL}/operatives/${operativeId}`, operative, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`OperativeCreate: ${err.message}`);
    }
}

export const LocationsList = async (): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        const response: AxiosResponse<LoginResponse> = await axios.get(`${API_BASE_URL}/locations`, ApiHeaderConfig);
        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`LocationsList: ${err.message}`);
    }
}

export const OperativeOpenedList = async (page: number): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        const response: AxiosResponse<LoginResponse> = await axios.get(`${API_BASE_URL}/operatives/opened?pageSize=10&page=${page}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`OperativeOpenedList: ${err.message}`);
    }
};

export const ReceipeByOperative = async (id: number, page: number): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        const response: AxiosResponse<LoginResponse> = await axios.get(`${API_BASE_URL}/operatives/receipe/${id}?pageSize=10&page=${page}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`ReceipeByOperative: ${err.message}`);
    }
};

export const GetCustomerByRut = async (rut: string): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        const response: AxiosResponse<LoginResponse> = await axios.get(`${API_BASE_URL}/customer/byrut/${rut}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`GetCustomerByRut: ${err.message}`);
    }
};

export const ReceipeCreate = async (recipe: any): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        recipe.company_id = getCompanyID();

        const response: AxiosResponse<LoginResponse> = await axios.post(`${API_BASE_URL}/operatives/receipe`, recipe, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`OperativeCreate: ${err.message}`);
    }
}

export const CustomerCreate = async (customer: any): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        customer.company_id = String(getCompanyID());

        const response: AxiosResponse<LoginResponse> = await axios.post(`${API_BASE_URL}/customer`, customer, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`OperativeCreate: ${err.message}`);
    }
}


export const ReceipesOpenedList = async (page: number): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        const response: AxiosResponse<LoginResponse> = await axios.get(`${API_BASE_URL}/operatives/receipes/opened?pageSize=10&page=${page}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`ReceipesOpenedList: ${err.message}`);
    }
};

export const ReceipesClosedList = async (page: number): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        const response: AxiosResponse<LoginResponse> = await axios.get(`${API_BASE_URL}/operatives/receipes/closed?pageSize=10&page=${page}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`ReceipesOpenedList: ${err.message}`);
    }
};

export const GetRecipeByID = async (id: number): Promise<any> => {
    try {
        if (!isLoggedIn()) {
            throw new Error('User not authorized');
        }

        const response: AxiosResponse<LoginResponse> = await axios.get(`${API_BASE_URL}/operatives/receipe/byid/${id}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`GetRecipeByID: ${err.message}`);
    }
};
