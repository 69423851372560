import React from 'react';

const NotFound: React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh',
            width: '100%',
        }}>
            <img src="/assets/images/no-web-content-found.png" alt="Not Found" width={'50%'} />
        </div>
    );
};

export default NotFound;
