import EditNoteIcon from '@mui/icons-material/EditNote';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import Link from '@mui/material/Link';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import { formatRut } from '../../../../source/utils/extends';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const CustomersList = (props: any) => {
    const [page, setPage] = useState(0);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        props.handleChangePage(newPage + 1);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>RUT</StyledTableCell>
                        <StyledTableCell>Nombre</StyledTableCell>
                        <StyledTableCell>Teléfono</StyledTableCell>
                        <StyledTableCell>e-Mail</StyledTableCell>
                        <StyledTableCell>Direccioón</StyledTableCell>
                        <StyledTableCell align='center'>Editar</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props?.contractData?.customers?.map((contract: any) => (
                        <StyledTableRow key={contract.id}>
                            <StyledTableCell>{formatRut(contract.rut)}</StyledTableCell>
                            <StyledTableCell>{contract.first_name} {contract.second_name}</StyledTableCell>
                            <StyledTableCell>{contract.cell_phone}</StyledTableCell>
                            <StyledTableCell>
                                <Link href={`mailto:${contract.email}`}>{contract.email}</Link>
                            </StyledTableCell>
                            <StyledTableCell>{contract.address}</StyledTableCell>
                            <StyledTableCell align='center'>
                                <Link href="#" onClick={() => props.handleEditSupplier (contract.id)}> <EditNoteIcon /> </Link>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            colSpan={3}
                            count={props?.contractData?.total}
                            rowsPerPage={10}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default CustomersList;
