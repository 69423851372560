import axios from 'axios';
import { IProductsCreateDTO } from '../core/dto/Products.dto';
import IProduct from '../core/interfaces/IProducts';
import { getCompanyID, isLoggedIn } from './accessControl';
import ApiHeaderConfig, { API_BASE_URL } from './header.config';

export const getProducts = async (page: number)  => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/products?pageSize=10&page=${page}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los productos:', error);
        throw error;
    }
};

export const getProductsFilter = async (page: number, filter: string)  => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/products?pageSize=10&page=${page}&searchTerm=${filter}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los productos:', error);
        throw error;
    }
};

export const getProductById = async (productId: string) : Promise<IProduct> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/products/${productId}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener el producto:', error);
        throw error;
    }
};

export const getProductBySku = async (productId: string) : Promise<IProduct> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/products/bysku/${productId}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener el producto:', error);
        throw error;
    }
};

// Crear un nuevo producto
export const createProduct = async (productData: IProductsCreateDTO) : Promise<IProduct> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        delete productData.total;
        productData.company_id = String(getCompanyID());
        const response = await axios.post(`${API_BASE_URL}/products`, productData, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al crear el producto:', error);
        throw error;
    }
};

// Actualizar un producto existente
export const updateProduct = async (productId: number, productData: any) : Promise<IProduct> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.put(`${API_BASE_URL}/products/${productId}`, productData, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el producto:', error);
        throw error;
    }
};

// Eliminar un producto
export const deleteProduct = async (productId: string) => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.delete(`${API_BASE_URL}/products/${productId}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar el producto:', error);
        throw error;
    }
};
