import axios, { AxiosResponse } from 'axios';

import IOperativeVoucher from '../core/interfaces/IVoucher';
import { isLoggedIn } from './accessControl';
import ApiHeaderConfig, { API_BASE_URL } from './header.config';

export const CristalColorsList = async (): Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response: AxiosResponse<IOperativeVoucher[]> = await axios.get(`${API_BASE_URL}/cristal-color`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`VoucherList: ${err.message}`);
    }
}