// src/api.ts
import axios, { AxiosResponse } from 'axios';

import { IOperativeVoucherDTO, IOperativeVoucherUpdateDTO } from '../core/dto/Voucher.dto';
import IOperativeVoucher from '../core/interfaces/IVoucher';
import { getCompanyID, isLoggedIn } from './accessControl';
import ApiHeaderConfig, { API_BASE_URL } from './header.config';

export const VoucherGetById = async (idVoucher: number): Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {

        const response: AxiosResponse<IOperativeVoucher> = await axios.get(`${API_BASE_URL}/vouchers/${idVoucher}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`VoucherGetById: ${err.message}`);
    }
}

export const VoucherGetByReceipeId = async (idVoucher: number): Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {

        const response: AxiosResponse<IOperativeVoucher> = await axios.get(`${API_BASE_URL}/vouchers/byreceipe/${idVoucher}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`VoucherGetById: ${err.message}`);
    }
}

export const VoucherList = async (page: number, closed: boolean = false): Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response: AxiosResponse<IOperativeVoucher[]> = await axios.get(`${API_BASE_URL}/vouchers?pageSize=10&page=${page}&closed=${closed}`, ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`VoucherList: ${err.message}`);
    }
}

export const VoucherCreate = async (voucher: IOperativeVoucherDTO): Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        voucher.company_id = String(getCompanyID());
        const response = await axios.post(`${API_BASE_URL}/vouchers`,
            voucher,
            ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`VoucherCreate: ${err.message}`);
    }
};

export const VoucherUpdate = async (idVoucher: number, voucher: IOperativeVoucherUpdateDTO): Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.put(`${API_BASE_URL}/vouchers/${idVoucher}`,
            voucher,
            ApiHeaderConfig);

        return response.data;
    } catch (e) {
        const err = e as Error;
        console.log(`VoucherUpdate: ${err.message}`);
    }
};
