import BackspaceIcon from '@mui/icons-material/Delete';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SailingIcon from '@mui/icons-material/ShoppingCart';
import { Link } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { formatNumberWithRegex } from '../../../../../src/source/utils/extends';
import IProduct, { emptyProduct } from '../../../../core/interfaces/IProducts';
import { getProductBySku } from '../../../../source/apiProducts';
import ProductSearchModal from '../../Search/ProductSearchModal';

const TAX_RATE = 0.19;
const includeTaxes = true;
const CanChangeSaleValue = true;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1976d2",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ccyFormat(num: number | undefined) {
    return `${num?.toFixed(0)}`;
}

function priceRow(qty: number, unit: number) {
    return qty * unit;
}

function createRow(info: IProduct, qty: number, saleValue: number): IProduct {
    info.value_sale = saleValue;
    info.total = priceRow(qty, saleValue);
    info.quantity = qty;
    return info;
}

function subtotal(items: readonly IProduct[]): number | undefined {
    return items.map(({ total }) => total).reduce((sum, i) => Number(sum) + Number(i), 0);
}

const ProductsTableCtrl = (props: any) => {
    const [product, setProduct] = useState<IProduct>(emptyProduct);
    const [rows, setRows] = useState<IProduct[]>(props.products ? props.products : [] as IProduct[]);
    const [code, setCode] = useState<string>("");
    const [qty, setQty] = useState<number>(1);
    const [saleValue, setSaleValue] = useState<number>(0);
    const [invoiceSubtotal, setInvoiceSubtotal] = useState<number | undefined>(0);
    const [invoiceTaxes, setInvoiceTaxes] = useState<number>(0);
    const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
    const [showSearch, setShowSearch] = useState(false);

    const handleGetProductInfo = async () => {
        if (code !== "") {
            try {
                const response = await getProductBySku(code);

                setProduct(response);

                setSaleValue(Number((response as IProduct).value_sale));
            } catch (error) {
                console.log((error as Error).message)
            }
        }
    };

    const calculateTotal = (newRows: any): number => {
        let subTotal = Number(subtotal(newRows));
        let taxes = 0;
        let total = 0;

        if (includeTaxes) {
            total = subTotal;
            subTotal = Math.round((subTotal / (1 + TAX_RATE)));
            taxes = total - subTotal;
        } else {
            taxes = Math.round(subTotal * TAX_RATE);
            total = subTotal + taxes;
        }

        setInvoiceSubtotal(subTotal);
        setInvoiceTaxes(taxes);
        setInvoiceTotal(total);
        return total;
    }

    const handleAddProductToSale = () => {

        if (code === "" || qty === 0 || saleValue === 0) {
            return false;
        };

        let newRows = rows;

        if (validateCodeExists(code)) {
            newRows = rows.map(row => {
                if (row.sku === code) {
                    row.value_sale = saleValue;
                    row.quantity = Number(row.quantity) + qty;
                    row.total = priceRow(row.quantity, Number(saleValue));
                }
                return row;
            });
        }
        else
            newRows.push(createRow(product, qty, saleValue));

        setRows(newRows);

        const total = calculateTotal(newRows);

        cleanProduct();

        props.handleOnTotalChange(total);
    };

    const handleDeleteProduct = (code: string) => {
        let newRows = rows.filter(row => row.sku !== code);

        setRows(newRows);

        const total = calculateTotal(newRows);

        props.handleOnTotalChange(total);
    }

    const validateCodeExists = (code: string) => {
        let exists = false;
        rows.forEach(row => {
            if (row.sku === code) {
                exists = true;
            }
        });
        return exists;
    };

    const cleanProduct = () => {
        setProduct(emptyProduct);
        setQty(1);
        setSaleValue(0);
        setCode("");
    };

    const handleSearch = () => {
        setShowSearch(true);
    }

    const handleSelectProduct = (product: IProduct) => {
        setProduct(product);
        setCode(product.sku);
        setSaleValue(Number(product.value_sale));
    }

    useEffect(() => {
        props.handleProductSaleUpdated?.(rows);
    }, [rows.length])


    useEffect(() => {
        calculateTotal(rows);
    }, [])

    return (
        <>
            <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                <TextField
                    margin="dense"
                    id="code"
                    label="Codigo"
                    name="code"
                    variant="standard"
                    required
                    sx={{ m: 1, minWidth: 70, textAlign: 'right' }}
                    onBlur={e => { handleGetProductInfo() }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => { setCode(e.target.value) }}
                    value={code}
                />
                <Button onClick={handleSearch} sx={{ marginLeft: '-10px' }}>
                    <SearchRoundedIcon />
                </Button>

                <TextField
                    margin="dense"
                    label="Descripción"
                    variant="standard"
                    required
                    sx={{ m: 1, minWidth: 100 }}
                    value={product?.name}
                    disabled={true}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    id="first_name"
                    label="Qty"
                    name="qty"
                    variant="standard"
                    sx={{ m: 1, minWidth: 70 }}
                    onChange={e => { setQty(Number(e.target.value)) }}
                    value={qty}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    label="Valor"
                    variant="standard"
                    value={saleValue}
                    onChange={e => { setSaleValue(Number(e.target.value)) }}
                    sx={{ m: 1, minWidth: 100 }}
                    disabled={!CanChangeSaleValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button
                    onClick={handleAddProductToSale}>
                    <SailingIcon />
                </Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 200 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={3}>
                                Detalle
                            </TableCell>
                            <TableCell align="right">Price</TableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell>Productos:</StyledTableCell>
                            <StyledTableCell align="right">Qty.</StyledTableCell>
                            <StyledTableCell align="right">Valor</StyledTableCell>
                            <StyledTableCell align="right">Total</StyledTableCell>
                            <StyledTableCell align="right">Del</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <TableCell>{row.name} - ({row.brand})</TableCell>
                                <TableCell align="right">{row.quantity}</TableCell>
                                <TableCell align="right">{formatNumberWithRegex(row.value_sale)}</TableCell>
                                <TableCell align="right">{(formatNumberWithRegex(row.total))}</TableCell>
                                <TableCell align="right">
                                    <Link href="#" onClick={() => { handleDeleteProduct(String(row.sku)) }}><BackspaceIcon /></Link>
                                </TableCell>
                            </StyledTableRow >
                        ))}
                        <TableRow>
                            <TableCell rowSpan={3} />
                            <TableCell colSpan={2}>Subtotal</TableCell>
                            <TableCell align="right">{formatNumberWithRegex(invoiceSubtotal)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>IVA</TableCell>
                            <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
                            <TableCell align="right">{(formatNumberWithRegex(invoiceTaxes))}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Total</TableCell>
                            <TableCell align="right">{(formatNumberWithRegex(invoiceTotal))}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <ProductSearchModal
                open={showSearch}
                handleSelectProduct={handleSelectProduct}
                handleClose={() => setShowSearch(false)}
            />
        </>
    );
};

export default ProductsTableCtrl;