import { getAuthCookie, getCompanyID } from './accessControl';

export const API_BASE_URL = 'https://app.opticontrol.cl/api';

const token: string | null = getAuthCookie();

const ApiHeaderConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Empresa-UUID': getCompanyID()
    }
}

export default ApiHeaderConfig;
