import MoreVertIcon from '@mui/icons-material/ProductionQuantityLimits';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import 'dayjs/locale/es';
import { useEffect, useState } from 'react';


import IProduct, { emptyProduct } from '../../../../core/interfaces/IProducts';
import { GetAllSuppliers } from '../../../../source/apiSuppliers';
import '../../../../source/css/dialog-title.css';

export default function CreateProductCtrl(props: any) {
    const [errorMsg, setErrorMsg] = useState('');

    const [infoProducto, setInfoProducto] = useState<IProduct>(props.infoProducto?.sku ? props.infoProducto : emptyProduct);
    const isEditing = !!props.infoProducto?.sku;
    const [suppliersCount, setSuppliersCount] = useState('');
    const [dataSuppliers, setDataSuppliers] = useState({ suppliers: [] });

    const loadSuppliers = async () => {
        const data = await GetAllSuppliers(1);
        setDataSuppliers(data);
        setSuppliersCount(data.length)
    }

    useEffect(() => {
        loadSuppliers();
    }, [])

    const handleChange = (event: any) => {
        const { name, value, type } = event.target;

        let newValue = value;

        if (type === 'number') {
            newValue = parseInt(value);
        };

        setInfoProducto(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const validateFields = (): boolean => {
        if (infoProducto.name === undefined || infoProducto.name === '') {
            setErrorMsg('El nombre del operativo es obligatorio');
            return false;
        }

        if (infoProducto.sku === undefined || infoProducto.sku === '') {
            setErrorMsg('El SKU es obligatorio');
            return false;
        }

        props.handleSave(infoProducto, isEditing);
        return true;
    }

    const handleInputChange = (event: any) => {
        event.target.value = event.target.value.toUpperCase(); // Convertir a mayúsculas
    };

    const handleChangeCheckbox = (event: any) => {
        const { name } = event.target;

        let newValue = !eval(`infoProducto.${name}`);

        setInfoProducto(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle className="custom-dialog-title" sx={{ marginBottom: '10px' }}>
                <MoreVertIcon /> Agregar/Editar Productos
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Información del Producto:
                </DialogContentText>
                <Box>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="sku"
                            name="sku"
                            label="SKU"
                            fullWidth
                            required
                            variant="standard"
                            onChange={handleChange}
                            value={infoProducto?.sku}
                            inputProps={{
                                onInput: handleInputChange,
                                maxLength: 15
                            }}
                            disabled={isEditing}
                        />
                    </Stack>

                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="idName"
                            label="Nombre del Producto"
                            name="name"
                            fullWidth
                            required
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 50
                            }}
                            value={infoProducto?.name}
                        />
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="brand"
                            name="brand"
                            label="Marca"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 40
                            }}
                            value={infoProducto?.brand}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="shape"
                            name="shape"
                            label="Forma"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 50
                            }}
                            value={infoProducto?.shape}
                        />
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <FormControl sx={{ m: 1, minWidth: 420 }} size="small" style={{ marginTop: '3px' }}>
                            <InputLabel id="demo-simple-select-label" style={{ marginLeft: '-13px' }}>Material Producto</InputLabel>
                            <Select
                                id="material_id"
                                name="material_id"
                                onChange={handleChange}
                                label="Material Producto"
                                variant="standard"
                                value={infoProducto?.material_id}
                            >
                                <MenuItem value={1}>ORGÁNICO</MenuItem>
                                <MenuItem value={2}>MINERAL</MenuItem>
                                <MenuItem value={3}>VIDRIO</MenuItem>
                                <MenuItem value={4}>OTRO</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 420 }} size="small" style={{ marginTop: '3px' }}>
                            <InputLabel id="demo-simple-select-label" style={{ marginLeft: '-13px' }}>Proveedor</InputLabel>
                            <Select
                                id="supplier_id"
                                name="supplier_id"

                                onChange={handleChange}
                                label="Proveedor"
                                variant="standard"
                                value={infoProducto?.supplier_id}
                            >
                                {dataSuppliers.suppliers?.map((supplier: any) => {
                                    return <MenuItem key={supplier.id} value={supplier.id}>{supplier.name} ({supplier.email})</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>

                        <FormControl sx={{ m: 0 }} fullWidth variant="standard">
                            <InputLabel htmlFor="standard-adornment-amount">Precio Costo</InputLabel>
                            <Input
                                id="value_cost"
                                name="value_cost"
                                type='number'
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                value={infoProducto?.value_cost}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 0 }} fullWidth variant="standard">
                            <InputLabel htmlFor="standard-adornment-amount">Precio Venta</InputLabel>
                            <Input
                                id="value_sale"
                                name="value_sale"
                                type='number'
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                value={infoProducto?.value_sale}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>

                        <FormControl sx={{ m: 0 }} fullWidth variant="standard" >
                            <InputLabel htmlFor="standard-adornment-amount">Cantidad Inicial</InputLabel>
                            <Input
                                id="quantity"
                                name="quantity"
                                type='number'
                                startAdornment={<InputAdornment position="start">Q</InputAdornment>}
                                value={infoProducto?.quantity}
                                onChange={handleChange}
                                disabled={isEditing}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 0 }} fullWidth variant="standard" >
                            <FormControlLabel
                                control={<Checkbox
                                    id='active'
                                    name='active'
                                    onChange={handleChangeCheckbox}
                                    checked={infoProducto?.active}
                                />}
                                label="¿ Producto Activo ?"
                                labelPlacement="end"
                                style={{ marginRight: '40px' }}
                            />
                        </FormControl>
                    </Stack>

                </Box>

                {errorMsg !== '' &&
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMsg}
                    </Alert>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={validateFields}>Grabar</Button>
            </DialogActions>
        </Dialog>
    );
}