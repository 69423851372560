import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import React, { useEffect, useState } from 'react';

import Customer from '../../../../core/interfaces/ICustomer';
import { LocationsList } from '../../../../source/api';
import '../../../../source/css/dialog-title.css';
import CustomerSearchModal from '../../../components/Search/CustomerSearchModal';

export interface OperativoHeader {
    date_at: Date
    name: string
    commune: string
    ville: string
    address: string
    encargado: string
    celular1: string
    cargo2: string
    encargado2: string
    celular2: string
    is_closed: boolean
}

export default function CreateOperative(props: any) {
    const today: any = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [showSearch, setShowSearch] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState<number>(0);

    const [infoOperativo, setInfoOperativo] = useState<OperativoHeader>({
        date_at: new Date(today),
        name: "Operativo del día: " + dayjs().format('DD-MM-YYYY'),
        commune: "",
        ville: "",
        address: "",
        encargado: "",
        celular1: "",
        cargo2: "",
        encargado2: "",
        celular2: "",
        is_closed: false,
    });

    const [comunasCount, setComunasCount] = useState('');
    const [location, setLocation] = useState([]);

    const loadLocations = async () => {
        const data = await LocationsList();
        setLocation(data);
        setComunasCount(data.length)
    }

    useEffect(() => {
        loadLocations();
    }, [comunasCount])

    const handleUpdateDate = (date: any) => {
        setInfoOperativo(prevInfo => ({
            ...prevInfo,
            date_at: date,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        setInfoOperativo(prevInfo => ({
            ...prevInfo,
            [name]: value,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const validateFields = (): boolean => {
        if (infoOperativo.name === '') {
            setErrorMsg('El nombre del Operativo es obligatorio.')
            return false;
        } else if (infoOperativo.commune === '') {
            setErrorMsg('La comuna es obligatoria.')
            return false;
        } else if (infoOperativo.ville === '') {
            setErrorMsg('La Villa es obligatoria.')
            return false;
        } else if (infoOperativo.address === '') {
            setErrorMsg('La Dirección es obligatoria.')
            return false;
        }

        props.handleSave(infoOperativo);
        return true;
    }

    const buildLocation = (): any => {
        if (Array.isArray(location) && location.length > 0) {
            let region: string = "";
            let comp: any[] = [];

            location.forEach(allRegions => {
                if (region !== allRegions['region_nombre']) {
                    region = allRegions['region_nombre'];

                    comp.push(
                        <MenuItem key={`region_${region}`} disabled value="">
                            <em>{region}</em>
                        </MenuItem>
                    );

                    const comunasDeLaRegion = location.filter(data => data['region_nombre'] === region);
                    comp.push(
                        ...comunasDeLaRegion.map((loc) => (
                            <MenuItem key={`comuna_${loc['comuna_nombre']}`} value={loc['comuna_nombre']}>
                                {loc['comuna_nombre']}
                            </MenuItem>
                        ))
                    );
                }
            });
            return comp;
        }

        return <React.Fragment />;
    };

    const handleSearch = (type: number) => {
        if (type === 1)
            setSelectedCustomer(1);
        else
            setSelectedCustomer(2);
        setShowSearch(true);
    }

    const handleSelectCustomer = (customer: Customer) => {
        if (selectedCustomer === 1)
            setInfoOperativo(prevInfo => ({
                ...prevInfo,
                encargado: String(customer.first_name) + ' ' + String(customer.second_name),
                celular1: String(customer.cell_phone),
            }));
        else        
            setInfoOperativo(prevInfo => ({
                ...prevInfo,
                encargado2: String(customer.first_name) + ' ' + String(customer.second_name),
                celular2: String(customer.cell_phone),
            }));
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle className="custom-dialog-title" sx={{ marginBottom: '10px' }}>Agregar/Editar Operativo</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Información inicial para el Operativo:
                </DialogContentText>
                <Box>
                    <FormControl size="small" style={{ marginTop: '15px' }}>
                        <Stack direction="row" spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    defaultValue={dayjs(today)}
                                    name="date_at"
                                    format='DD/MM/YYYY'
                                    views={['year', 'month', 'day']}
                                    onChange={handleUpdateDate}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </FormControl>
                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Nombre del Operativo"
                            fullWidth
                            required
                            variant="standard"
                            onChange={handleChange}
                            value={infoOperativo?.name}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <FormControl sx={{ m: 1, minWidth: 420 }} size="small" style={{ marginTop: '3px' }}>
                            <InputLabel required id="demo-simple-select-label" style={{ marginLeft: '-13px' }}>Comuna</InputLabel>
                            <Select
                                id="commune"
                                name="commune"
                                required
                                value={infoOperativo?.commune}
                                onChange={handleChange}
                                label="Comuna"
                                variant="standard"
                            >
                                {buildLocation()}
                            </Select>
                        </FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="ville"
                            name="ville"
                            label="Villa"
                            required
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            value={infoOperativo?.ville}
                        />
                    </Stack>
                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="address"
                            name="address"
                            label="Dirección"
                            required
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            value={infoOperativo?.address}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="encargado"
                            name="encargado"
                            label="Encargado 1"
                            required
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            value={infoOperativo?.encargado}
                        />

                        <Button onClick={(e) => handleSearch(1)} sx={{ marginLeft: '-10px' }}>
                            <SearchRoundedIcon />
                        </Button>

                        <FormControl sx={{ m: 0 }} variant="standard" fullWidth>
                            <InputLabel htmlFor="standard-adornment-amount">Teléfono:</InputLabel>
                            <Input
                                id="celular1"
                                name="celular1"
                                startAdornment={<InputAdornment position="start">+56</InputAdornment>}
                                value={infoOperativo?.celular1}
                                inputProps={{
                                    maxLength: 9,
                                }}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <FormControl sx={{ m: 1, minWidth: 380 }} size="small" style={{ marginTop: '3px' }}>
                            <InputLabel id="demo-simple-select-label" style={{ marginLeft: '-13px' }}>Segundo Contacto</InputLabel>
                            <Select
                                id="cargo2"
                                name="cargo2"
                                value={infoOperativo?.cargo2}
                                onChange={handleChange}
                                variant="standard"
                            >
                                <MenuItem value={'Vicepresidente'}>Vicepresidente</MenuItem>
                                <MenuItem value={'Tesorero'}>Tesorero</MenuItem>
                                <MenuItem value={'Secretaria'}>Secretaria</MenuItem>
                                <MenuItem value={'Vocal'}>Vocal</MenuItem>
                                <MenuItem value={'Otro'}>Otro</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="encargado2"
                            name="encargado2"
                            label="Encargado 2"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            value={infoOperativo?.encargado2}
                        />

                        <Button onClick={(e) => handleSearch(2)} sx={{ marginLeft: '-10px' }}>
                            <SearchRoundedIcon />
                        </Button>

                        <FormControl sx={{ m: 0 }} variant="standard" fullWidth>
                            <InputLabel htmlFor="standard-adornment-amount">Teléfono</InputLabel>
                            <Input
                                id="celular2"
                                name="celular2"
                                startAdornment={<InputAdornment position="start">+56</InputAdornment>}
                                value={infoOperativo?.celular2}
                                inputProps={{
                                    maxLength: 9
                                }}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Stack>
                </Box>

                {errorMsg !== '' &&
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMsg}
                    </Alert>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={validateFields}>Grabar</Button>
            </DialogActions>
            <CustomerSearchModal
                open={showSearch}
                handleSelectCustomer={handleSelectCustomer}
                handleClose={() => setShowSearch(false)}
            />
        </Dialog>
    );
}