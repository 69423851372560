import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import IProduct from '../../../core/interfaces/IProducts';
import { createSuppliers, getSupplierById, getSuppliers, updateSuppliers } from '../../../source/apiSuppliers';
import CreateSupplierCtrl from './Create';
import { SupplierList } from './List';

export default function Suppliers() {

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [infoSupplier, setInfoSupplier] = useState({});

    const handleEditSupplier = async (id: number) => {
        const obj = await getSupplierById(id);
        setInfoSupplier(obj);
        setOpen(true)
    };

    const handleClickOpen = () => {
        setInfoSupplier({});
        setOpen(true);
    };

    const handleClose = () => {
        setInfoSupplier({});
        setOpen(false);
    };

    const loadData = async (page: number) => {
        try {
            const response = await getSuppliers(page);
            setData(response);
            setIsLoading(false)
        } catch (error) {
            console.log((error as Error).message)
        }
    }

    const SaveData = async (product: any, isEditing: boolean) => {
        try {
            setIsLoading(true)
            handleClose();
            if (!isEditing) {
                await createSuppliers(product);
            } else {
                await updateSuppliers(product.id, product);
            }
        } catch (error) {
            console.log((error as Error).message)
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
    }

    useEffect(() => {
        setIsLoading(true)
        loadData(0);
    }, [])

    return (
        <React.Fragment>
            <CssBaseline />
            <Container component="main" >
                <Paper variant="outlined">
                    <Typography component="h1" variant="h4" align="center" sx={{ margin: '20px' }}>
                        Proveedores
                    </Typography>

                    {!isLoading &&
                        <SupplierList
                            contractData={data}
                            handleEditSupplier={(id: number) => { handleEditSupplier(id) }}
                            handleChangePage={(page: number) => { loadData(page) }}
                        />
                    }

                    <Typography align="right" style={{ margin: '10px' }}>
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleClickOpen}>Agregar Proveedor</Button>
                    </Typography>
                </Paper>
            </Container>
            {open &&
                <CreateSupplierCtrl
                    open={open}
                    infoSupplier={infoSupplier}
                    handleClose={handleClose}
                    handleSave={(product: IProduct, isEditing: boolean) => SaveData(product, isEditing)}
                />
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}