import EventBusyIcon from '@mui/icons-material/EventBusy';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import Link from '@mui/material/Link';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { formatDate } from '../../../../source/utils/extends';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const OperativosList = (props: any) => {
    const [page, setPage] = useState(props.page);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        props.handleChangePage(newPage);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Fecha</StyledTableCell>
                        <StyledTableCell>Nombre</StyledTableCell>
                        <StyledTableCell>Comuna</StyledTableCell>
                        <StyledTableCell>Villa</StyledTableCell>
                        <StyledTableCell>Dirección</StyledTableCell>
                        <StyledTableCell align='center'>Cerrada?</StyledTableCell>
                        <StyledTableCell align='center'>Cerrar</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props?.contractData?.operatives?.map((contract: any) => (
                        <StyledTableRow key={contract.id}>
                            <StyledTableCell>{contract.correlative_id}</StyledTableCell>
                            <StyledTableCell>{formatDate(contract.date_at)}</StyledTableCell>
                            <StyledTableCell>{contract.name}</StyledTableCell>
                            <StyledTableCell>{contract.commune}</StyledTableCell>
                            <StyledTableCell>{contract.ville}</StyledTableCell>
                            <StyledTableCell>{contract.address}</StyledTableCell>
                            <StyledTableCell align='center'>{contract.is_closed ? "Si" : "No"}</StyledTableCell>
                            <StyledTableCell align='center'>
                                {contract.is_closed ? <HighlightOffIcon /> : <Link href="#" onClick={() => props.handleCloseContract(contract.id)}> <EventBusyIcon /> </Link>}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            colSpan={3}
                            count={props?.contractData?.total}
                            rowsPerPage={10}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default OperativosList;
