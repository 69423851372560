import axios from 'axios';

import { getCompanyID, isLoggedIn } from './accessControl';
import ApiHeaderConfig, { API_BASE_URL } from './header.config';

export const getCustomers = async (page: number)  => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/customer?pageSize=10&page=${page}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los Clientes:', error);
        throw error;
    }
};

export const getCustomersFilter = async (page: number, filter: string)  => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/customer?pageSize=10&page=${page}&searchTerm=${filter}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los Clientes:', error);
        throw error;
    }
};

// Obtener un producto por su ID
export const getCustomerById = async (customerId: number) : Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/customer/${customerId}`, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al obtener el Cliente:', error);
        throw error;
    }
};
// Crear un nuevo producto
export const createCustomers = async (customerData: any) : Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        customerData.company_id = String(getCompanyID());
        const response = await axios.post(`${API_BASE_URL}/customer`, customerData, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al crear el Cliente:', error);
        throw error;
    }
};

// Actualizar un producto existente
export const updateCustomers = async (customerId: number, customerData: any) : Promise<any> => {
    if (!isLoggedIn()) {
        throw new Error('User not authorized');
    }

    try {
        const response = await axios.put(`${API_BASE_URL}/customer/${customerId}`, customerData, ApiHeaderConfig);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el Cliente:', error);
        throw error;
    }
};

