import Cookies from 'js-cookie';

const COOKIE_TOKEN: string = "AUTH_COOKIE";
const COMPANY_ID: string = "UUID_COMPANY";
const USER_ID: string = "UUID_USER";

export const isLoggedIn: () => boolean = () => localStorage.getItem(COOKIE_TOKEN) ? true : false

export const getAuthCookie = (): string | null => {
    const cookieToken = localStorage.getItem(COOKIE_TOKEN);
    const lastSeen = localStorage.getItem("last_seen");

    if (cookieToken && lastSeen) {
        const lastSeenDate = new Date(lastSeen);
        const now = new Date();

        const timeDiff = (now.getTime() - lastSeenDate.getTime()) / (1000 * 60 * 60);

        if (timeDiff > 4) {
            localStorage.removeItem(COOKIE_TOKEN);
            localStorage.removeItem("last_seen");
            return null;
        }
    }

    return cookieToken ? cookieToken : null;
}

export const setAuthCookie = (token: string) => {
    localStorage.setItem(COOKIE_TOKEN, token);
    localStorage.setItem("last_seen", new Date().toISOString());
}


export const setCompanyID = (uuid: string) => {
    localStorage.setItem(COMPANY_ID, uuid,);
}

export const getCompanyID: () => string | null = () =>
    localStorage.getItem(COMPANY_ID)

export const setUserID = (uuid: string) => {
    localStorage.setItem(USER_ID, uuid);
}

export const getUserID: () => string | null = () =>
    localStorage.getItem(USER_ID)