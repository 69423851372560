import { Box, Button, Grid, Stack } from '@mui/material';
import Input from '@mui/material/Input';
import { useEffect, useState } from 'react';


const NumberInput = (props: any) => {
    const estiloDiv = {
        padding: '1px',
        fontSize: '10px',
        color: 'red',
    };
    const [value, setValue] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [valueStr, setValueStr] = useState<string>("");

    const handleChangeValue = (newValue: string) => {
        if (props.useSignal && (!newValue.startsWith("+") && !newValue.startsWith("-"))) {
            setErrorMsg("Agregue el signo +/-");
        }
        else {
            setErrorMsg("");
        }

        if (props.useSignal) {
            newValue = newValue.replace(".", ",");
        }

        setValueStr(newValue);
    };

    return (
        <Grid container>
            <Box
                sx={{
                    fontSize: '13px',
                    color: 'grey',
                    fontFamily: 'Roboto',
                    textShadow: '1px 1px 1px lightgrey',
                }}>
                {props.label}
            </Box>
            <Stack direction={'row'}>
                <Box
                    sx={{
                        width: 50,
                        m: 1,
                    }}
                >
                    <Input
                        id="discount_value"
                        name="discount_value"
                        value={valueStr}
                        onChange={e => handleChangeValue(e.target.value)}
                        sx={{
                            marginLeft: '3px',
                        }}
                        inputProps={{
                            min: 0,
                            style: { textAlign: 'center' },
                            maxLength: 5
                        }}
                    />
                    {(errorMsg && props.useSignal) &&
                        <div style={estiloDiv}>
                            Error: {errorMsg}
                        </div>
                    }
                </Box>
            </Stack>
        </Grid>
    );
};

export default NumberInput;