import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Divider } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { PureComponent, RefObject } from "react";
import Customer from "../../../../core/interfaces/ICustomer";
import Receipe from "../../../../core/interfaces/Receipe";
import { formatRut } from "../../../../source/utils/extends";
import DiagonalLineGridItem from "../DiagonalLineGrid";
import './receipe-style.css';
import dayjs from 'dayjs';


interface Props {
    text?: string;
    Receipe: Receipe;
    Customer: Customer;
    forwardedRef: any;
}

interface State {
    checked: boolean;
    receipe: Receipe;
    customer: Customer;
    fontSize: string;
}

const CheckedBoxIcon = (props: any) => {
    return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <CheckBoxOutlineBlankIcon sx={{ fontSize: 30 }} />
            <span style={{ marginTop: '1px' }}>{props.children}</span>
        </div>
    );
}

const CheckBoxIcon = (props: any) => {
    return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <CheckBoxOutlinedIcon sx={{ fontSize: 30 }} />
            <span style={{ marginTop: '1px' }}>{props.children}</span>
        </div>);
}

const LabelValued = (props: any) => {
    return (
        <div style={{ marginTop: '-5px' }}>
            <strong>{props.children}</strong>
        </div>
    );
}

export class ReceipePdfFormat extends PureComponent<Props, State> {
    canvasEl: RefObject<HTMLCanvasElement>;

    constructor(props: Props) {
        super(props);
        this.state = { checked: false, receipe: props.Receipe, customer: props.Customer, fontSize: '12px' };
        this.canvasEl = React.createRef();
    }

    componentDidMount() {
        const ctx = this.canvasEl.current?.getContext("2d");
        if (ctx) {
            ctx.beginPath();
            ctx.arc(95, 50, 40, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fillStyle = "rgb(200, 0, 0)";
            ctx.fillRect(85, 40, 20, 20);
            ctx.save();
        }
    }

    setRef = (ref: any) => (this.canvasEl = ref);

    getValuedDiagnosis = (value: any) => {
        let component = null;
        if (!value || value == 0) {
            component = <DiagonalLineGridItem />;
        } else {
            component = <Grid item xs={2} sm={2} sx={{ marginLeft: '20px', marginBottom: '15px', border: 1, borderColor: 'black', height: '25px', textAlign: 'center' }}>
                <LabelValued>{value.toString()}</LabelValued>
            </Grid>
        }
        return component;
    };

    getCheckedDiagnosis = (title: string, value: boolean) => {
        let component = null;
        if (!value) {
            component = <CheckBoxIcon>{title}</CheckBoxIcon>;
        } else {
            component = <CheckedBoxIcon>{title}</CheckedBoxIcon>;
        }
        return component;
    };

    render() {

        return (
            <div className="recipe-print" ref={this.props.forwardedRef}>
                <Grid container spacing={1} sx={{ m: 1 }}>
                    <Grid item xs={6} sm={4} sx={{ textAlign: 'left', fontSize: this.state.fontSize }}>
                        @optilicaletelier.chile<br />
                        Óptica Móvil: +569 4217 0786<br />
                        contacto@opticasletelier.cl<br />
                        Opticas Letelier<br />
                    </Grid>
                    <Grid item xs={6} sm={4} sx={{ textAlign: 'center' }}>
                        <Box>
                            <img src="./assets/images/logo.png" alt="logo" width={'150px'} />
                        </Box>
                    </Grid>
                </Grid>

                <div className="recipe-info">
                    <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                        <Grid item xs={2} sm={2} sx={{ textAlign: 'left' }}>
                            <strong>Nombre:</strong>
                        </Grid>
                        <Grid item xs={2} sm={2} sx={{ textAlign: 'left' }}>
                            {this.state.customer?.first_name} {this.state.customer?.second_name}<br />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                        <Grid item xs={2} sm={2} sx={{ textAlign: 'left' }}>
                            <strong>RUT:</strong>
                        </Grid>
                        <Grid item xs={4} sm={4} sx={{ textAlign: 'left' }}>
                            {formatRut(this.state.customer?.rut)}
                        </Grid>
                        <Grid item xs={6} sm={4} sx={{ textAlign: 'left' }}>
                            <strong>Edad:</strong>
                            {this.state.customer?.age}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                        <Grid item xs={2} sm={2} sx={{ textAlign: 'left' }}>
                            <strong>RP.</strong>
                        </Grid>
                    </Grid>

                    <Divider sx={{ borderBottomWidth: 4, m: 1, width: '93%', backgroundColor: 'black' }} />

                    <div className="prescription-info">
                        <Grid container spacing={1} sx={{ marginLeft: 1, marginTop: '-10px', fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sx={{ textAlign: 'left' }}>
                                <strong>LEJOS</strong>
                            </Grid>
                            <Grid item xs={3} sm={2} sx={{ marginLeft: '30px', marginBottom: '10px' }}>
                                Esfera
                            </Grid>
                            <Grid item xs={2} sm={2} sx={{ marginLeft: '-30px', }}>
                                &nbsp;&nbsp;Cilindro
                            </Grid>
                            <Grid item xs={2} sm={2} sx={{ marginLeft: '40px' }}>
                                Eje
                            </Grid>
                        </Grid>

                        {/* valores de los lentes */}
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sm={3} sx={{ textAlign: 'right', marginBottom: '15px' }}>
                                <strong>OD:</strong>
                            </Grid>
                            {this.getValuedDiagnosis(this.state.receipe.esfera_od)}
                            {this.getValuedDiagnosis(this.state.receipe.cilindro_od)}
                            {this.getValuedDiagnosis(this.state.receipe.eje_od)}
                        </Grid>
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sm={3} sx={{ textAlign: 'right' }}>
                                <strong>OI:</strong>
                            </Grid>
                            {this.getValuedDiagnosis(this.state.receipe.esfera_oi)}
                            {this.getValuedDiagnosis(this.state.receipe.cilindro_oi)}
                            {this.getValuedDiagnosis(this.state.receipe.eje_oi)}
                        </Grid>

                        <Divider sx={{ borderBottomWidth: 4, m: 1, width: '93%', backgroundColor: 'black' }} />

                        <Grid container spacing={1} sx={{ marginLeft: 1, marginTop: '-15px', fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sx={{ textAlign: 'left' }}>
                                <strong>CERCA</strong>
                            </Grid>
                            <Grid item xs={3} sm={2} sx={{ marginLeft: '30px', marginBottom: '10px' }}>
                                Esfera
                            </Grid>
                            <Grid item xs={2} sm={2} sx={{ marginLeft: '-30px' }}>
                                Cilindro
                            </Grid>
                            <Grid item xs={2} sm={2} sx={{ marginLeft: '40px' }}>
                                Eje
                            </Grid>
                        </Grid>

                        {/* valores de los lentes */}
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sm={3} sx={{ textAlign: 'right', marginBottom: '15px' }}>
                                <strong>OD:</strong>
                            </Grid>
                            {this.getValuedDiagnosis(this.state.receipe.esfera_od2)}
                            {this.getValuedDiagnosis(this.state.receipe.cilindro_od2)}
                            {this.getValuedDiagnosis(this.state.receipe.eje_od2)}
                        </Grid>
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sm={3} sx={{ textAlign: 'right' }}>
                                <strong>OI:</strong>
                            </Grid>
                            {this.getValuedDiagnosis(this.state.receipe.esfera_oi2)}
                            {this.getValuedDiagnosis(this.state.receipe.cilindro_oi2)}
                            {this.getValuedDiagnosis(this.state.receipe.eje_oi2)}
                        </Grid>

                        <Divider sx={{ borderBottomWidth: 4, m: 1, width: '93%', backgroundColor: 'black' }} />

                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={1} sm={1} sx={{ textAlign: 'left' }}>
                                <strong>ADD:</strong>
                            </Grid>
                            <Grid item xs={3} sm={4} sx={{ borderBottom: 1 }}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={6} sm={4} >
                                Media (60cm a 1 mtr computador)
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={1} sm={1} sx={{ textAlign: 'left' }}>
                                <strong>ADD:</strong>
                            </Grid>
                            <Grid item xs={3} sm={4} sx={{ borderBottom: 1 }}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={5} sm={4} >
                                Cerca (30 - 40 cm de lectura)
                            </Grid>
                        </Grid>
                    </div>

                    <div className="diagnostic-info">
                        <Grid container spacing={1} sx={{ marginLeft: 1, marginTop: '4px', fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sx={{ textAlign: 'left' }}>
                                <strong>DIAGNÓSTICO</strong>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={4} sm={4} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Miopía', this.state.receipe.is_miopia)}
                            </Grid>
                            <Grid item xs={4} sm={4} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Astigmatismo', this.state.receipe.is_astigmatismo)}
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={4} sm={4} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Hipermetropía', this.state.receipe.is_hipermetropia)}
                            </Grid>
                            <Grid item xs={4} sm={4} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Presbicie', this.state.receipe.is_presbicie)}
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ fontSize: this.state.fontSize, textAlign: 'right', marginTop: '-20px' }}>
                            <Grid item xs={10} md={10}>
                                DP: 123/34
                            </Grid>
                        </Grid>
                    </div>

                    <div className="glass-info">
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sx={{ textAlign: 'left' }}>
                                <strong>CRISTAL</strong>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={2.5} sm={2.5} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Antireflejo', this.state.receipe.cristal_antireflejo)}
                            </Grid>
                            <Grid item xs={2.5} sm={2.5} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Bifocal', this.state.receipe.cristal_bifocal)}
                            </Grid>
                            <Grid item xs={2.5} sm={2.5} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Filtro Azul', this.state.receipe.cristal_filtroazul)}
                            </Grid>
                            <Grid item xs={2.5} sm={2.5} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Lejos', this.state.receipe.cristal_lejos)}
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginLeft: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={2.5} sm={2.5} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Fotocromático', this.state.receipe.cristal_fotocromatico)}
                            </Grid>
                            <Grid item xs={2.5} sm={2.5} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Multifocal', this.state.receipe.cristal_multifocal)}
                            </Grid>
                            <Grid item xs={2.5} sm={2.5} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Filtro UV', this.state.receipe.cristal_filtrouv)}
                            </Grid>
                            <Grid item xs={2.5} sm={2.5} sx={{ width: '300px' }}>
                                {this.getCheckedDiagnosis('Cerca', this.state.receipe.cristal_cerca)}
                            </Grid>
                        </Grid>
                    </div>

                    <div className="footer">
                        <Grid container spacing={1} sx={{ m: 1, fontSize: this.state.fontSize }}>
                            <Grid item xs={3} sx={{ textAlign: 'left' }}>
                                <strong>NOTA:</strong> {this.state.receipe.observaciones}
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} sx={{ marginLeft: 5, fontSize: this.state.fontSize, textAlign: 'center' }}>
                            <Grid item xs={3} sx={{ borderBottom: 1, borderColor: 'black', textAlign: 'center' }}>
                                {dayjs(this.state.receipe.recipe_date).format('DD/MM/YYYY')}
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: 'left' }}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={3} sx={{ borderBottom: 1 }}>
                                &nbsp;
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginLeft: 5, fontSize: this.state.fontSize, textAlign: 'center' }}>
                            <Grid item xs={3} sx={{ textAlign: 'center' }}>
                                <strong>Fecha</strong>
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: 'center' }}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: 'center' }}>
                                <strong>Firma</strong>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div >
        );
    }
}

export const FunctionalreceipePdfFormat = React.forwardRef((props: any, ref: any) => {
    // eslint-disable-line max-len
    return <ReceipePdfFormat ref={ref} Receipe={props.Receipe} Customer={props.Customer} forwardedRef={props.forwardedRef} />;
});
