import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import React, { useEffect, useState } from 'react';

import Customer from '../../../core/interfaces/ICustomer';
import Receipe from '../../../core/interfaces/Receipe';
import { GetRecipeByID, ReceipesOpenedList } from '../../../source/api';
import ReceipesOpenedListCtrl from '../../components/Receipes/ListOpened';
import VoucherCreateMainCtrl from './Create/index';
import NotFound from '../../components/NotFound';

export default function VentasPending(props: any) {
    const today: any = dayjs().format('YYYY-MM-DD HH:mm:ss');

    const setRecipeDefault = (): Receipe => {
        return {
            operative_id: props.OperativeId,
            customer_id: 0,
            recipe_date: new Date(today),
            recipe_end_date: new Date(today),
            distance: "",
            esfera_od: "0",
            esfera_oi: "0",
            cilindro_od: "0",
            cilindro_oi: "0",
            eje_od: 0,
            eje_oi: 0,
            distance2: "",
            esfera_od2: "0",
            esfera_oi2: "0",
            cilindro_od2: "0",
            cilindro_oi2: "0",
            eje_od2: 0,
            eje_oi2: 0,
            add: 0,
            add2: 0,
            dp: "",
            dp2: "",
            observaciones: "",
            is_miopia: false,
            is_astigmatismo: false,
            is_hipermetropia: false,
            is_presbicie: false,
            cristal_antireflejo: false,
            cristal_bifocal: false,
            cristal_filtroazul: false,
            cristal_fotocromatico: false,
            cristal_multifocal: false,
            cristal_filtrouv: false,
            cristal_lejos: false,
            cristal_cerca: false,
            cristal_color_id: 0
        };
    };

    const [data, setData] = useState([]);
    const [receipeData, setReceipeData] = useState<Receipe>(setRecipeDefault());
    const [customerData, setCustomerData] = useState<Customer>({} as Customer);
    const [isLoading, setIsLoading] = useState(false);
    const [isSavedOK, setIsSavedOK] = useState(false);
    const [atencionId, setAtencionId] = useState(0);
    const [titleOperative, setTitleOperative] = useState('' as string);

    const handleSelectAtencion = (id: number) => {
        loadReceipe(id);
    };

    const loadReceipe = async (atencionId: number) => {
        const data2 = await GetRecipeByID(atencionId);
        setReceipeData(data2);
        setTitleOperative(data2.operative?.name)
        setCustomerData(data2.customer)
        setAtencionId(atencionId);
    }

    const loadData = async (page: number) => {
        try {
            const response = await ReceipesOpenedList(page);
            setData(response);
        } catch (error) {
            console.log((error as Error).message)
        }
    }

    useEffect(() => {
        loadData(1);
    }, [isLoading])


    return (
        <React.Fragment>
            <CssBaseline />
            <Container component="main" >
                <Paper variant="outlined" style={{ margin: '10px' }}>
                    {(atencionId === 0) ?
                        <>
                            <ReceipesOpenedListCtrl
                                Title="Recetas Pendientes de Venta"
                                isForSale
                                contractData={data}
                                handleSelectOperative={(id: number) => { handleSelectAtencion(id) }}
                                handleCloseContract={(id: number) => { }}
                                handleChangePage={(page: number) => { loadData(page) }}
                                handleSaleReceipe={(id: number) => { handleSelectAtencion(id) }}
                            />
                            <Typography align="center" style={{ margin: '15px' }}>
                                <Button variant="outlined" startIcon={<AddIcon />} onClick={() => { }}>Agregar Venta</Button>
                            </Typography>
                        </>
                        :
                        (atencionId > 0 ?
                            <VoucherCreateMainCtrl
                                InfoOperation={titleOperative}
                                ReceipeInfo={receipeData}
                                CustomerInfo={customerData}
                                handleBackTo={() => { setAtencionId(0); setReceipeData(setRecipeDefault()); }}
                            />
                            :
                            <Typography component="h4" variant="h4" align="center">
                                <NotFound />
                            </Typography>
                        )
                    }
                    {isSavedOK &&
                        <Alert variant="filled" severity="success">
                            <AlertTitle>Correcto</AlertTitle>
                            Atención Guardada correctamente
                        </Alert>
                    }
                </Paper>
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}