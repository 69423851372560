import React, { useState, FormEvent } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


interface LoginFormProps {
    executeLogin: (email: string, password: string) => void;
    errorMsg: string;
}

const LoginForm: React.FC<LoginFormProps> = (props: any) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        // Aquí puedes manejar la lógica de inicio de sesión, por ejemplo, enviar los datos al servidor
        props.executeLogin(email, password);
    };

    return (
        <Container maxWidth="xs">
            <Box sx={{ marginTop: 8, textAlign: 'center' }}>
                <Typography variant="h4">Iniciar Sesión</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Correo Electrónico"
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Contraseña"
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
                        Iniciar Sesión
                    </Button>
                </form>
                {props.errorMsg &&
                    <Stack sx={{ width: '100%', marginTop: '20px' }} spacing={2}>
                        <Alert variant="filled" severity="error">
                            {props.errorMsg}
                        </Alert>
                    </Stack>
                }
            </Box>
        </Container>
    );
};

export default LoginForm;
