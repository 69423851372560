import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import React, { useEffect, useState } from 'react';

import Customer from '../../../../core/interfaces/ICustomer';
import IProduct from '../../../../core/interfaces/IProducts';
import IOperativeVoucher from '../../../../core/interfaces/IVoucher';
import Receipe from '../../../../core/interfaces/Receipe';
import ProductsTableCtrl from '../../../components/Products/List';
import { info } from 'console';

interface VoucherCreateCtrlProps {
    Customer: Customer,
    Receipe: Receipe,
    Voucher: IOperativeVoucher,
    InfoOperation: string
    handlerUpdateVoucher: (voucher: IOperativeVoucher) => void
}

const VoucherCreateCtrl: React.FC<VoucherCreateCtrlProps> = (props: any) => {
    const [voucher, setVoucher] = useState<IOperativeVoucher>(props.Voucher);
    const customer : Customer = props.Customer;
    const infoReceipe : Receipe = props.Receipe;

    const handleChangeTotal = (value: number, type: number) => {
        if (type === 1) {
            setVoucher({ ...voucher, total_without_discount: value, total: value, balance: value - Number(voucher.credit), discount_percent: 0, discount_value: 0})
        }
        else if (type === 2) {
            if (value <= Number(voucher.total))
                setVoucher({ ...voucher, credit: value, balance: Number(voucher.total) - value, discount_percent: 0, discount_value: 0 })
        }
    };

    const handleChangeCheckbox = (event: any) => {
        const { name } = event.target;

        let newValue = !eval(`voucher.${name}`);

        const valueName = name.replace('is_', '');

        if (newValue) {
            setVoucher(prevInfo => ({
                ...prevInfo,
                [name]: newValue,
                [valueName]: 0,
            }));
        } else {
            setVoucher(prevInfo => ({
                ...prevInfo,
                [name]: newValue,
            }));
            calculateTotal(valueName, 0);
        }
    };

    const calculateTotal = (name: string, value: number) => {
        let valueAccum = 0;
        switch (name) {
            case 'cash':
                valueAccum = Number(voucher.debit) + Number(voucher.credit_card) + Number(voucher.transfert) + value;
                break;
            case 'debit':
                valueAccum = Number(voucher.cash) + Number(voucher.credit_card) + Number(voucher.transfert) + value;
                break;
            case 'credit_card':
                valueAccum = Number(voucher.cash) + Number(voucher.debit) + Number(voucher.transfert) + value;
                break;
            case 'transfert':
                valueAccum = Number(voucher.cash) + Number(voucher.debit) + Number(voucher.credit_card) + value;
                break;
            default:
                break;
        }
        if (valueAccum <= Number(voucher.total))
            setVoucher(prevInfo => ({
                ...prevInfo,
                [name]: value,
                balance: Number(voucher.total) - (valueAccum),
                credit: valueAccum,
            }));
    };

    const handleChangeDateVoucher = (date: any) => {
        setVoucher(prevInfo => ({
            ...prevInfo,
            voucher_end_date: date,
        }));
    };

    const handleChangeVoucher = (event: any) => {
        const { name, value } = event.target;

        if (name === 'discount_percent') {
            if (value < 0 || value > 100) {
                return false;
            }
        };

        if (name === 'discount_value') {
            if (value < 0 || value > Number(voucher.total_without_discount)) {
                return false;
            }
        };

        let discount_value = 0;
        let discount_percent = 0;

        if (name === 'discount_percent' || name === 'discount_value') {
            if (name === 'discount_percent') {
                discount_percent = Number(value);
                discount_value = Math.round(Number(voucher.total_without_discount) * (Number(value) / 100));
            } else {
                discount_value = Math.round(Number(value));
                discount_percent = Math.round((Number(value) * 100) / Number(voucher.total_without_discount));
            }

            setVoucher(prevInfo => ({
                ...prevInfo,
                [name]: value,
                total: Number(voucher.total_without_discount) - discount_value,
                balance: Number(voucher.total_without_discount) - discount_value - Number(voucher.credit),
                discount_percent: discount_percent,
                discount_value: discount_value
            }));
        } else if (name === 'cash' || name === 'debit' || name === 'credit_card' || name === 'transfert') {
            calculateTotal(name, Number(value));
        } else {
            setVoucher(prevInfo => ({
                ...prevInfo,
                [name]: value,
            }));
        }
    }

    const handleProductSaleUpdated = (product: IProduct[]) => {
        voucher.products = product;
        setVoucher({ ...voucher });
    };

    useEffect(() => {
        props.handlerUpdateVoucher(voucher)
    });

    const tomorrow = dayjs().add(-2, 'day');
    const nineAM = dayjs().set('hour', 9).startOf('hour');
    const ninePM = dayjs().set('hour', 21).startOf('hour');

    return (
        <>
            <CssBaseline />
            <Container maxWidth="md">
                <Box>
                    {/* fecha */}
                    <FormControl sx={{ m: 0 }} fullWidth >
                        <Stack direction="row" spacing={1} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DateTimePicker
                                    name="voucher_end_date"
                                    label="Fecha Entrega"
                                    minDate={tomorrow}
                                    minTime={nineAM}
                                    maxTime={ninePM}
                                    value={dayjs(voucher?.voucher_end_date)}
                                    onChange={handleChangeDateVoucher}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </FormControl>

                    {/*  Cliente */}
                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="rut"
                            label="RUT"
                            name="rut"
                            variant="standard"
                            value={customer?.rut}
                            sx={{ m: 1, minWidth: 100 }}
                            disabled={true}
                        />
                        <TextField
                            margin="dense"
                            id="first_name"
                            label="Nombre"
                            name="first_name"
                            fullWidth
                            variant="standard"
                            value={`${customer?.first_name} ${customer?.second_name}`}
                            disabled={true}
                        />
                    </Stack>


                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="age"
                            label="Edad"
                            name="age"
                            variant="standard"
                            type="number"
                            disabled={true}
                            value={customer?.age}
                            sx={{ m: 1, minWidth: 100 }}
                        />
                        <TextField
                            margin="dense"
                            id="rut"
                            label="Teléfono:"
                            name="rut"
                            variant="standard"
                            value={customer?.cell_phone}
                            fullWidth
                            disabled={true}
                        />
                    </Stack>

                    <Divider sx={{ margin: '20px' }} />

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Atendido en:"
                            placeholder='Operativo'
                            name="name"
                            fullWidth
                            variant="standard"
                            value={props.InfoOperation}
                            sx={{ m: 1, minWidth: 100 }}
                            disabled={true}
                        />
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="rut"
                            label="Observación:"
                            name="rut"
                            fullWidth
                            variant="standard"
                            value={infoReceipe?.observaciones}
                            sx={{ m: 1, minWidth: 100 }}
                            disabled={true}
                        />
                    </Stack>

                    <ProductsTableCtrl
                        products={voucher.products}
                        handleOnTotalChange={(total: number) => handleChangeTotal(total, 1)}
                        handleProductSaleUpdated={(product: IProduct[]) => { handleProductSaleUpdated(product); }}
                    />

                    {/*  Descuento */}
                    <FormControl>
                        <Stack direction="row" spacing={3} style={{ marginTop: '20px' }}>
                            <TextField
                                id="discount_percent"
                                name="discount_percent"
                                label="Descuento %"
                                variant="standard"
                                type="number"
                                value={voucher?.discount_percent}
                                sx={{ minWidth: 70 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChangeVoucher}
                                disabled={voucher?.is_cash || voucher?.is_debit || voucher?.is_credit_card || voucher?.is_transfert}
                            />
                            <FormControl sx={{ m: 0 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-amount">Descuento en Pesos</InputLabel>
                                <Input
                                    id="discount_value"
                                    name="discount_value"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={voucher?.discount_value}
                                    onChange={handleChangeVoucher}
                                    disabled={voucher?.is_cash || voucher?.is_debit || voucher?.is_credit_card || voucher?.is_transfert}
                                />
                            </FormControl>
                        </Stack>
                    </FormControl>

                    {/*  Forma de pago */}
                    <Box sx={{ display: 'flex' }}>
                        <FormControl
                            component="fieldset"
                            variant="standard"
                            required
                            sx={{ marginTop: '20px', backgroundColor: '#f5f5f5', width: '100%' }}
                        >
                            <FormLabel component="legend" sx={{ marginLeft: '0px' }}>Formas de Pago</FormLabel>
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    id='is_cash'
                                    name='is_cash'
                                    label="Efectivo"
                                    labelPlacement="end"
                                    sx={{ m: 1, minWidth: 150 }}
                                    onChange={handleChangeCheckbox}
                                    checked={voucher?.is_cash}
                                />
                                {voucher?.is_cash &&
                                    <FormControl variant="standard">
                                        <Input
                                            autoFocus
                                            id="cash"
                                            name="cash"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            value={voucher?.cash}
                                            onChange={handleChangeVoucher}
                                            sx={{ marginLeft: '40px' }}
                                        />
                                    </FormControl>
                                }
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="T. Débito"
                                    labelPlacement="end"
                                    sx={{ m: 1, minWidth: 150 }}
                                    id='is_deyo bit'
                                    name='is_debit'
                                    onChange={handleChangeCheckbox}
                                    checked={voucher?.is_debit}
                                />
                                {voucher?.is_debit &&
                                    <FormControl sx={{ m: 0 }} variant="standard">
                                        <Input
                                            id="debit"
                                            name="debit"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            value={voucher?.debit}
                                            onChange={handleChangeVoucher}
                                            autoFocus
                                            sx={{ marginLeft: '40px' }}
                                        />
                                    </FormControl>
                                }
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="T. Crédito"
                                    labelPlacement="end"
                                    sx={{ m: 1, minWidth: 150 }}
                                    id='is_credit_card'
                                    name='is_credit_card'
                                    onChange={handleChangeCheckbox}
                                    checked={voucher?.is_credit_card}
                                />
                                {voucher?.is_credit_card &&
                                    <FormControl sx={{ m: 0 }} variant="standard">
                                        <Input
                                            id="credit_card"
                                            name="credit_card"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            value={voucher?.credit_card}
                                            onChange={handleChangeVoucher}
                                            autoFocus
                                            sx={{ marginLeft: '40px' }}
                                        />
                                    </FormControl>
                                }
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Transferencia"
                                    labelPlacement="end"
                                    sx={{ m: 1, minWidth: 150 }}
                                    id='is_transfert'
                                    name='is_transfert'
                                    onChange={handleChangeCheckbox}
                                    checked={voucher?.is_transfert}

                                />
                                {voucher?.is_transfert &&
                                    <FormControl sx={{ m: 0 }} variant="standard">
                                        <Input
                                            id="transfert"
                                            name="transfert"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            value={voucher?.transfert}
                                            onChange={handleChangeVoucher}
                                            autoFocus
                                            sx={{ marginLeft: '40px' }}
                                        />
                                    </FormControl>
                                }
                            </Stack>
                        </FormControl>
                    </Box>

                    {/*  Totales */}
                    <FormControl fullWidth>
                        <Stack direction="row" spacing={3} style={{ marginTop: '20px' }}>
                            <FormControl sx={{ m: 0 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-amount">Total</InputLabel>
                                <Input
                                    id="total"
                                    name="total"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={voucher?.total}
                                    disabled={true}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 0 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-amount">Abono</InputLabel>
                                <Input
                                    id="credit"
                                    name="credit"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={voucher?.credit}
                                    disabled={true}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 0 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-amount">Saldo</InputLabel>
                                <Input
                                    id="balance"
                                    name="balance"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={voucher?.balance}
                                    disabled={true}
                                />
                            </FormControl>
                        </Stack>
                    </FormControl>
                </Box>
            </Container >
        </>
    );
};

export default VoucherCreateCtrl;
