import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { OperativeClose, OperativeCreate, OperativeList } from '../../../source/api';
import CreateOperative from './Create';
import { OperativosList } from './List';


export default function Operativos(props: any) {

    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openModalOperative, setOpenModalOperative] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [operativeId, setOperativeId] = useState(0);
    const [page, setPage] = useState(0);

    const handleModalOperative = (id: number) => {
        setOpenModalOperative(true)
        setOperativeId(id)
    };

    const handleCloseOperative = async () => {
        try {
            setIsLoading(true);
            await OperativeClose(operativeId);
            setOpenModalOperative(false);
            setOperativeId(0);
        } catch (error) {
            console.log((error as Error).message)
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const loadData = async (page: number) => {
        try {
            setPage(page);
            setIsLoading (true)
            const response = await OperativeList(page);
            setData(response);
            setTimeout(() => {
                setIsLoading (false)
            }, 1000);
    
        } catch (error) {
            console.log((error as Error).message)
        }
    }

    const SaveData = async (operative: any) => {
        try {
            setIsLoading (true)
            handleClose();
            await OperativeCreate(operative)
            await loadData(0);
        } catch (error) {
            console.log((error as Error).message)
        }
        setTimeout(() => {
            setIsLoading (false)
        }, 1000);
    }

    useEffect(() => {
        loadData(0);
    }, [])

    return (
        <React.Fragment>
            <CssBaseline />
            <Container component="main" >
                <Paper variant="outlined">
                    <Typography component="h1" variant="h4" align="center" sx={{margin: '20px'}}>
                        Historial Operativos
                    </Typography>

                    {!isLoading &&
                        <OperativosList
                            contractData={data}
                            page={page}
                            handleCloseContract={(id: number) => { handleModalOperative(id) }}
                            handleChangePage={(page: number) => { loadData(page) }}
                        />
                    }
                    <Alert severity="info">En la opción Cerrar. Puede finalizar un operativo.
                    </Alert>

                    <Typography align="right" style={{ margin: '10px' }}>
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleClickOpen}>Agregar Operativo</Button>
                    </Typography>
                </Paper>
            </Container>
            {open &&
                <CreateOperative
                    open={open}
                    handleClose={handleClose}
                    handleSave={SaveData}
                />
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* cerrar el operativo */}
            <Dialog
                open={openModalOperative}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Cerrar Operativo"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        ¿Esta seguro de cerrar el operativo {operativeId}?

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenModalOperative(false) }}>Cancelar</Button>
                    <Button onClick={() => { handleCloseOperative() }} autoFocus>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}