import MoreVertIcon from '@mui/icons-material/ProductionQuantityLimits';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import 'dayjs/locale/es';
import { Fragment, useEffect, useState } from 'react';
import { LocationsList } from '../../../../source/api';

import ICustomer from '../../../../core/interfaces/ICustomer';
import '../../../../source/css/dialog-title.css';
import { formatRut } from '../../../../source/utils/extends';

export default function CreateCustomerCtrl(props: any) {
    const [errorMsg, setErrorMsg] = useState('');

    const [infoCustomer, setInfoCustomer] = useState<ICustomer>(props.infoCustomer?.id > 0 ? props.infoCustomer : { active: true });
    const isEditing = props.infoCustomer?.id > 0;
    const [comunasCount, setComunasCount] = useState('');
    const [location, setLocation] = useState([]);

    const loadLocations = async () => {
        const data = await LocationsList();
        setLocation(data);
        setComunasCount(data.length)
    }

    useEffect(() => {
        loadLocations();
    }, [comunasCount])

    const handleChange = (event: any) => {
        const { name, value, type } = event.target;

        let newValue = value;

        if (type === 'number') {
            newValue = parseInt(value);
        };

        setInfoCustomer(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const validateFields = (): boolean => {
        if (infoCustomer.first_name === undefined || infoCustomer.first_name === '') {
            setErrorMsg('El nombre del cliente es obligatorio');
            return false;
        }

        if (infoCustomer.second_name === undefined || infoCustomer.second_name === '') {
            setErrorMsg('El nombre del cliente es obligatorio');
            return false;
        }

        if (infoCustomer.rut === undefined || infoCustomer.rut === '') {
            setErrorMsg('El RUT del cliente es obligatorio');
            return false;
        }

        props.handleSave(infoCustomer, isEditing);
        return true;
    }


    const handleChangeCheckbox = (event: any) => {
        const { name } = event.target;

        let newValue = !eval(`infoCustomer.${name}`);

        setInfoCustomer(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const buildLocation = (): any => {
        if (Array.isArray(location) && location.length > 0) {
            let region: string = "";
            let comp: any[] = [];

            location.forEach(allRegions => {
                if (region !== allRegions['region_nombre']) {
                    region = allRegions['region_nombre'];

                    comp.push(
                        <MenuItem key={`region_${region}`} disabled value="">
                            <em>{region}</em>
                        </MenuItem>
                    );

                    const comunasDeLaRegion = location.filter(data => data['region_nombre'] === region);
                    comp.push(
                        ...comunasDeLaRegion.map((loc) => (
                            <MenuItem key={`comuna_${loc['comuna_nombre']}`} value={loc['comuna_nombre']}>
                                {loc['comuna_nombre']}
                            </MenuItem>
                        ))
                    );
                }
            });
            return comp;
        }

        return <Fragment />;
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle className="custom-dialog-title" sx={{ marginBottom: '10px' }}>
                <MoreVertIcon /> Agregar/Editar Cliente
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Información del Cliente:
                </DialogContentText>
                <Box>
                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="first_name"
                            name="first_name"
                            label="Nombre"
                            required
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 100,
                                width: '50%'
                            }}
                            value={isEditing ? formatRut(infoCustomer?.rut) : infoCustomer?.rut}
                            disabled={isEditing}
                        />
                    </Stack>

                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="first_name"
                            name="first_name"
                            label="Nombre"
                            fullWidth
                            required
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 100
                            }}
                            value={infoCustomer?.first_name}
                        />
                        <TextField
                            margin="dense"
                            id="second_name"
                            name="second_name"
                            label="Apellido"
                            fullWidth
                            required
                            variant="standard"
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 100
                            }}
                            value={infoCustomer?.second_name}
                        />
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <FormControl sx={{ m: 1, minWidth: 420 }} size="small" style={{ marginTop: '3px' }}>
                            <InputLabel id="demo-simple-select-label" style={{ marginLeft: '-13px' }}>Comuna</InputLabel>
                            <Select
                                id="idComuna"
                                name="commune"
                                value={infoCustomer?.commune}
                                onChange={handleChange}
                                label="Comuna"
                                variant="standard"
                            >
                                {buildLocation()}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="ville"
                            label="Villa"
                            name="ville"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            value={infoCustomer?.ville}
                        />
                    </Stack>

                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="idAddress"
                            name="address"
                            label="Dirección"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            value={infoCustomer?.address}
                        />
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            label="e-Mail"
                            fullWidth
                            variant="standard"
                            type='email'
                            onChange={handleChange}
                            inputProps={{
                                maxLength: 100
                            }}
                            value={infoCustomer?.email}
                        />

                        <FormControl sx={{ m: 0 }} variant="standard" fullWidth>
                            <InputLabel htmlFor="standard-adornment-amount">Teléfono</InputLabel>
                            <Input
                                id="cell_phone"
                                name="cell_phone"
                                startAdornment={<InputAdornment position="start">+56</InputAdornment>}
                                inputProps={{
                                    maxLength: 50
                                }}
                                value={infoCustomer?.cell_phone}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <FormControl sx={{ m: 1, minWidth: 420 }} size="small" style={{ marginTop: '3px' }}>
                            <InputLabel id="demo-simple-select-label" style={{ marginLeft: '-13px' }}>Sexo</InputLabel>
                            <Select
                                id="sex"
                                name="sex"
                                value={infoCustomer?.sex}
                                onChange={handleChange}
                                label="Sexo"
                                variant="standard"
                            >
                                <MenuItem value={'MASCULINO'}>Masculino</MenuItem>
                                <MenuItem value={'FEMENINO'}>Femenino</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 0 }} fullWidth variant="standard" >
                            <FormControlLabel
                                control={<Checkbox
                                    id='active'
                                    name='active'
                                    onChange={handleChangeCheckbox}
                                    checked={infoCustomer?.active}
                                />}
                                label="¿ Cliente Activo ?"
                                labelPlacement="end"
                                style={{ marginRight: '40px' }}
                            />
                        </FormControl>
                    </Stack>

                </Box>

                {errorMsg !== '' &&
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMsg}
                    </Alert>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={validateFields}>Grabar</Button>
            </DialogActions>
        </Dialog>
    );
}