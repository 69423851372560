import moment from 'moment';

export function formatDate(date: string | Date): string {
    let newData = moment(date).format("DD/MM/YYYY");
    if (moment(newData, "DD/MM/YYYY", true).isValid())
        return newData;
    return date instanceof Date ? date.toISOString() : date;
}

export function formatDateTime(date: string | Date): string {
    return moment(date).format("DD/MM/YYYY HH:mm");
}

export function formatLongDate(date: Date): string {
    return date.getDay() + ' de ' + date.toLocaleString('default', { month: 'long' }) + ' de ' + date.getFullYear();
}

export function formatLongDateTime(date: Date): string {
    return date.getDay() + ' de ' + date.toLocaleString('default', { month: 'long' }) + ' de ' + date.getFullYear() + ' a las ' + date.getHours() + ':' + date.getMinutes();
}

export function validarRut(rut: string): boolean {

    const rutRegex = /^\d{1,2}\.\d{3}\.\d{3}-[0-9kK]$/;

    if (!rutRegex.test(rut)) {
        return false;
    }

    const [numero, verificador] = rut.split('-');
    const rutNumerico = numero.replace(/\./g, '');

    let suma = 0;
    let multiplicador = 2;

    for (let i = rutNumerico.length - 1; i >= 0; i--) {
        suma += parseInt(rutNumerico.charAt(i)) * multiplicador;
        multiplicador = multiplicador === 7 ? 2 : multiplicador + 1;
    }

    const resultado: any = 11 - (suma % 11);

    const digitoVerificador = resultado === 11 ? '0' : resultado === 10 ? 'K' : resultado.toString();

    return digitoVerificador === verificador.toUpperCase();
};

export function formatRut (rut: string): string {
    if (rut.length === 0) {
        return '';
    }
    const rutNumerico = rut.slice(0, -1);
    const digitoVerificador = rut.slice(-1);
    rut = rutNumerico.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');
    let rutFormateado = '';
    for (let i = rut.length - 1; i >= 0; i--) {
        rutFormateado = rut[i] + rutFormateado;
        if (i % 3 === rut.length % 3 && i !== 0) {
            rutFormateado = '.' + rutFormateado;
        }
    }
    rutFormateado += '-' + digitoVerificador;
    return rutFormateado;
}

export function separarPalabras(cadena: string): string[] {
    const palabras = cadena.split(' ');

    if (palabras.length === 2) {
        const primeraParte = palabras.slice(0, 1).join(' ');
        const segundaParte = palabras.slice(1).join(' ');
        return [primeraParte, segundaParte];
    } else if (palabras.length === 3 || palabras.length === 4) {
        const primeraParte = palabras.slice(0, 2).join(' ');
        const segundaParte = palabras.slice(2).join(' ');
        return [primeraParte, segundaParte];
    } else {
        return [cadena]; // No se hace ninguna separación para otros casos
    }
}
export function formatNumberWithRegex(number: Number | null | undefined): string {
    let numString = number?.toString() ?? "";
    const [integerPart, decimalPart] = numString.split(',');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedNumber = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
    return formattedNumber;
}