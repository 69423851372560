import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MoreVertIcon from '@mui/icons-material/SearchSharp';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import 'dayjs/locale/es';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import IProducts from '../../../../core/interfaces/IProducts';
import { getProducts, getProductsFilter } from '../../../../source/apiProducts';
import '../../../../source/css/dialog-title.css';
import ProductsListCtrl from './ProductList';

export default function ProductSearchModal(props: any) {
    const [errorMsg, setErrorMsg] = useState('');
    const [products, setProducts] = useState([] as IProducts[]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const loadProducts = async () => {
        const data = await getProducts(page);
        setProducts(data);
    };

    const handleSearch = async (textToSearch: string) => {
        setPage(1);
        const data = await getProductsFilter(page, textToSearch);
        setProducts(data);
        setIsLoading(false);
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleProductSaleUpdated = (product: IProducts) => {
        props.handleSelectProduct(product);
        props.handleClose();
    }

    useEffect(() => {
        loadProducts();
    }, [page]);


    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle className="custom-dialog-search-title" sx={{ marginBottom: '10px' }}>
                <MoreVertIcon /> Buscar Productos
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        autoFocus
                        id="standard-basic"
                        placeholder='Buscar producto...'
                        sx={{ width: '90%', marginBottom: 1 }}
                        onChange={(e) => handleSearch((e.target as HTMLInputElement).value.toString())}
                    />
                    <Button onClick={(e) => handleSearch((e.target as HTMLInputElement).value.toString())} sx={{ width: '10%', marginTop: '10px' }}>
                        <SearchRoundedIcon />
                    </Button>
                </DialogContentText>
                <Box sx={{textAlign: 'center'}}>
                    {isLoading && 
                        <>
                            <br /><br />
                            <CircularProgress />
                        </>
                    }
                    {!isLoading &&
                        <ProductsListCtrl
                            page={page}
                            rows={products}
                            handleChangePage={handleChangePage}
                            handleProductSaleUpdated={handleProductSaleUpdated}
                        />
                    }
                </Box>

                {errorMsg !== '' &&
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMsg}
                    </Alert>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}