import IProductMaterial from "./IProductMaterial";

export default interface IProduct {
    detailId?: number;
    id: number;
    company_id: string;
    supplier_id: number;
    material_id: number;
    sku: string;
    brand?: string;
    name?: string;
    shape?: string;
    value_cost?: number | null;
    value_sale?: number | null;
    quantity?: number;
    total?: number;
    product_material?: IProductMaterial;
    active?: boolean;
    created_at?: Date;
    update_ad?: Date;
}

export interface IProductSale {
    id: number;
    sku: string;
    brand: string;
    name: string;
    total: number;
    qty: number;
};

export const emptyProduct: IProduct = {
    sku: "",
    brand: "",
    name: "",
    shape: "",
    active: true,
    value_cost: 0,
    value_sale: 0,
    company_id: "",
    quantity: 0,
    created_at: new Date(),
    update_ad: new Date(),
    id: 0,
    supplier_id: 0,
    material_id: 0,
    product_material: undefined,
    total: 0,
};

