import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import { CastDTOToProduct, CastVoucherToDTO, CastVoucherToUpdateDTO } from '../../../../core/dto/Voucher.dto';
import Customer from '../../../../core/interfaces/ICustomer';
import IProduct from '../../../../core/interfaces/IProducts';
import IOperativeVoucher, { emptyOperativeVoucher } from '../../../../core/interfaces/IVoucher';
import Receipe from '../../../../core/interfaces/Receipe';
import { VoucherCreate, VoucherGetByReceipeId, VoucherUpdate } from '../../../../source/apiVoucher';
import PrintCtrl from '../../../components/Print';
import ReceipeEditCtrl from '../../../components/Receipes/FormEdit';
import VoucherCreateCtrl from '../../../components/Voucher/FormCreate';
import SuccessScreen from './SuccessForm';

const steps = ['Revisión de Receta', 'Creación Nota de Venta', 'Impresión'];

export default function VoucherCreateMainCtrl(props: any) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [voucherInfo, setVoucherInfo] = useState<IOperativeVoucher>(emptyOperativeVoucher);
    const [openSaveAlert, setOpenSaveAlert] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const receipeInfo = props.ReceipeInfo;
    const customerInfo = props.CustomerInfo;

    const loadVoucherInfo = async (byReceipeId: number) => {
        const voucher = await VoucherGetByReceipeId(byReceipeId);
        if (voucher?.length > 0) {
            const products: IProduct[] = CastDTOToProduct(voucher[0].operative_voucher_detail);
            voucher[0].products = products;
            setVoucherInfo(voucher[0]);
        }
    }

    const handleNext = () => {
        if (activeStep === 1 && Number(voucherInfo.total_without_discount) > 0 && !isModified) {
            setOpenSaveAlert(true);
        }
        else if (activeStep === 2) {
            props.handleBackTo();
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            props.handleBackTo();
        } else if (activeStep === 2 && Number(voucherInfo.total_without_discount) > 0) {
            setOpenSaveAlert(true);
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handlerUpdateVoucher = (voucher: IOperativeVoucher) => {
        setVoucherInfo(voucher);
    };

    const handleSaveVoucher = () => {
        handleSaveData();
        setOpenSaveAlert(false);
    };

    const handleSaveData = async () => {

        setIsModified(true);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        let obj_to_save: IOperativeVoucher = voucherInfo;

        obj_to_save.operative_recipe_id = receipeInfo.id;

        if (obj_to_save.id === 0) {
            await VoucherCreate(CastVoucherToDTO(obj_to_save));
        } else {
            await VoucherUpdate(obj_to_save.id, CastVoucherToUpdateDTO(obj_to_save));
        }
    }

    useEffect(() => {

        setVoucherInfo({ ...voucherInfo, products: [] });

        loadVoucherInfo(receipeInfo.id);

    }, [activeStep === 0]);

    const setActiveControl = (step: number) => {
        let activeControl = null;
        switch (step) {
            case 0:
                activeControl =
                    <ReceipeEditCtrl
                        hasToolbox={false}
                        justForEdit={true}
                        Receipe={receipeInfo}
                        Customer={customerInfo}
                        handleOnSave={(customer: Customer, newReceipe: Receipe) => { }}
                        handleModifiedAlert={() => { return true; }}
                        handlBackTo={() => { }}
                    />;
                break;
            case 1:
                activeControl =
                    <VoucherCreateCtrl
                        InfoOperation={props.InfoOperation}
                        Receipe={receipeInfo}
                        Customer={customerInfo}
                        Voucher={voucherInfo}
                        handlerUpdateVoucher={(v: IOperativeVoucher) => { handlerUpdateVoucher(v) }}
                    />;
                break;
            case 2:
                activeControl =
                    <SuccessScreen
                        NumeroVoucher={voucherInfo.id}
                    >
                        <PrintCtrl
                            type='receipe'
                            InfoOperation={props.InfoOperation}
                            Receipe={receipeInfo}
                            Customer={customerInfo}
                            handlerUpdateVoucher={(v: IOperativeVoucher) => { handlerUpdateVoucher(v) }}
                        />
                    </SuccessScreen>;
                break;
            default:
                break;
        }
        return activeControl;
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} sx={{ margin: '20px' }}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>{setActiveControl(activeStep)} </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {activeStep !== 2 &&
                        <Button
                            color="inherit"
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                            variant="outlined"
                        >
                            Volver
                        </Button>
                    }
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext} variant="outlined">
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                    </Button>
                </Box>
            </React.Fragment>
            <Dialog
                open={openSaveAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmar
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ margin: '20px' }}>
                        <h3>
                            ¿Está seguro que desea guardar la Nota de Venta?
                        </h3>
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenSaveAlert(false) }}> Cancelar </Button>
                    <Button onClick={() => {
                        handleSaveVoucher();
                    }}> Aceptar </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}