import Typography from '@mui/material/Typography';


const SuccessScreen = (props: any) => {

    return (
        <div>
            <Typography variant="h3" sx={{ border: 1, borderColor: 'green', m: 8, p: 8 }}>
                Nota de Venta Creada exitosamente.
                <br />
                <br />
                Número: {props.NumeroVoucher}
            </Typography>
            {props.children}
        </div>
    );
};

export default SuccessScreen;
